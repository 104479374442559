import AOS from "aos";
import "aos/dist/aos.css";

export default defineNuxtPlugin((nuxtApp) => {
  if (typeof window !== "undefined") {
    nuxtApp.AOS = AOS.init({
      offset: 200,
      duration: 200, 
      delay: 0,
      anchorPlacement: 'top-bottom',
    });
  }
});