export const cases = [
  { icon: 'analysis', label: 'Data Analysis and Visualization', description: 'Seamlessly analyze and visualize your data with real-time insights. Transform complex datasets into clear visualizations effortlessly.' },
  { icon: 'code2', label: 'Code Development', description: 'Boost your coding prowess by experimenting with code variations and refining algorithms on the fly. Accelerate development without the hassle of constant trial and error.' },
  { icon: 'file', label: 'File Conversion', description: "Instantly convert document formats with ease. Whether it's transforming files into a different format or adapting content for compatibility, the plugin has you covered." },
  { icon: 'math', label: 'Mathematical Problem Solving', description: 'Tackle complex math problems effortlessly. Receive step-by-step solutions and delve into mathematical challenges without missing a beat.' },
  { icon: 'gif', label: 'GIF Creation', description: 'Infuse life into your projects by generating GIFs, adding a dynamic dimension to your coding endeavors.' },
  { icon: 'jpg', label: 'Image Cropping', description: 'Perfect your images by cropping them to precision. Achieve the desired visual impact with easy-to-use image cropping capabilities.' }
];

export const FAQList = [
  { q: 'What is the Data Analysis?', a: 'The Data Analysis enables you to analyze and effortlessly visualize your campaign datasets. You can run reports from diverse datasets, including Google Ads and Google Analytics, as well as perform tasks like solving simple math questions, converting document formats, and more.' },
  { q: 'Is the Data Analysis free?', a: "OpenAI recently introduced the Data Analysis in ChatGPT for all paying users. However, this feature comes at a cost of $20 per month, which might not be feasible for everyone. If you're looking for a free alternative, consider trying out Arvin first." },
  { q: "Is Arvin's Data Analysis free?", a: 'Yes, Arvin is a FREE product to start with. Each user will get 20 credits every day for FREE.' },
  { q: "How to Use Arvin's Data Analysis:", a: ['Download the Arvin plug-in.', 'Click the "Data Analysis" button in the plug-in.', 'Enter the exclusive Data Analysis web page.', 'Click the "+" to upload a document or start chatting directly.'], link: 'https://arvin.chat/how-to-use-code-interpreter/' },
  { q: 'Is this tool suitable for beginners?', a: 'Absolutely! Our Feature Data Analysis is designed to cater to coders of all proficiency levels. Beginners will find it immensely helpful in grasping concepts, while experts can expedite their development processes.' },
  { q: 'Which programming languages are supported?', a: "Arvin's Data Analysis works best with Python, and our list of supported languages is continuously expanding." },
  { q: 'Is my data secure when using the interpreter?', a: "Absolutely. Your data's privacy and security are paramount. Our interpreter runs your data within a controlled environment, ensuring the protection of your data and intellectual property." },
  { q: "Does Arvin's Data Analysis have the ability to collaborate with teammates on coding projects?", a: "Currently, in our Beta version, we don't support collaborative code editing and re-running directly. However, we are actively working on expanding our features, and collaborative editing is on our roadmap for the future. Stay tuned." },
  { q: "Will Arvin's Data Analysis open an API to third-party developers?", a: "We'll certainly take this idea into consideration and explore the possibility of opening an API for third-party developers to integrate with our data visualization tools in the future." },
  { q: 'How many langs does it recognize?', a: "Arvin's Data Analysis currently recognizes and supports multiple programming languages, including but not limited to Python, JavaScript, Java, C++, and more. However, as of now, only Python can be executed in the sandbox." }
];

const analyzingDatasetsPrompts = [
  { description: 'Load and preview the data', prompt: 'Can you load and preview the data?' },
  { description: 'Describe the data', prompt: 'Can you describe the data?' },
  { description: 'Explain this dataset in one paragraph', prompt: 'Can you explain this dataset in one paragraph?' },
  { description: 'Explain this dataset in simple terms', prompt: 'Can you explain this dataset in simple terms?' },
  { description: "Explain this dataset like I'm 5 years old", prompt: "Can you explain this dataset like I'm 5 years old?" },
  { description: 'Identify the main takeaway', prompt: 'What’s the main takeaway from this dataset?' },
  { description: 'Describe the rows and columns', prompt: 'What are the rows and columns in this dataset?' },
  { description: 'Provide insights', prompt: 'What insights do you see here? Give me a numbered list.' },
  { description: 'Create a graph', prompt: 'Can you create a graph using this data?' },
  { description: 'Create a chart', prompt: 'Can you create a chart using this data?' },
  { description: 'Create a heatmap', prompt: 'Can you create a heatmap using this data?' },
  { description: 'Identify trends', prompt: 'What are the trends shown in this data?' },
  { description: 'Top 10 points', prompt: 'Can you list the top 10 key points?' },
  { description: 'Turn into Article', prompt: 'Can you write me an article based on this dataset?' },
  { description: 'Quick recap', prompt: 'Can you write a one sentence recap of this data?' },
  { description: 'Clean Data', prompt: 'Can you clean this dataset?' },
  { description: 'Segment Data', prompt: 'Can you segment this data and create a table?' },
  { description: 'Create a presentation', prompt: 'Can you create a presentation based this dataset?' },
  { description: 'Create 10 visuals', prompt: 'Can you create 10 visuals to represent different data?' },
  { description: 'Word Cloud', prompt: 'Can you create a visual word cloud?' },
  { description: 'Beautify the graphs', prompt: 'Can you make the graphs more beautiful?' },
  { description: 'Visual Chart', prompt: 'Create a visual chart, based on this data.' },
  { description: 'Trends in a visual format', prompt: 'Show me the top trends in a visual format.' },
  { description: 'Key Lessons', prompt: 'What is the key lesson from this dataset?' }
];

const analyzingFinancialPrompts = [
  { description: 'Load and preview the data', prompt: 'Can you load and preview the data?' },
  { description: 'Describe the data', prompt: 'Can you describe the data?' },
  { description: 'Plot the closing price', prompt: 'Can you plot the closing price?' },
  { description: 'Find the date with the highest closing price', prompt: "What's the date with the highest closing price?" },
  { description: 'Calculate the percentage change', prompt: 'Can you calculate the daily percentage change?' },
  { description: 'Calculate moving averages', prompt: 'Can you calculate a 7-day moving average?' },
  { description: 'Create a candlestick chart', prompt: 'Can you create a candlestick chart?' },
  { description: 'Find the largest single day gain and loss', prompt: "What's the largest single day gain and loss?" },
  { description: 'Analyze volume', prompt: 'Can you analyze the volume?' },
  { description: 'Check for seasonality', prompt: 'Can you check for seasonality?' },
  { description: 'Compare price with volume', prompt: 'Can you compare the closing price with volume?' },
  { description: 'Calculate volatility', prompt: 'Can you calculate the price volatility?' },
  { description: 'Decompose the time series', prompt: 'Can you decompose the closing price time series?' },
  { description: 'Test for stationarity', prompt: 'Can you test for stationarity using the Augmented Dickey-Fuller test?' },
  { description: 'Autocorrelation and partial autocorrelation plots', prompt: 'Can you show the autocorrelation and partial autocorrelation plots?' },
  { description: 'Implement a time series model', prompt: 'Can you implement an ARIMA model to predict future prices?' },
  { description: 'Plot Bollinger Bands', prompt: 'Can you plot Bollinger Bands?' },
  { description: 'Find important dates', prompt: 'Can you identify dates of significant changes in volume or price?' },
  { description: 'Plot a histogram of the daily returns', prompt: 'Can you plot a histogram of daily returns?' },
  { description: 'Rolling window operations', prompt: 'Can you perform a rolling sum over a 7-day window?' },
  { description: 'Price Increase', prompt: "What's the duration of the fastest price increase from low to high?" },
  { description: 'Price Decrease', prompt: "What's the duration of the fastest price decrease from high to low?" }
];

const ImagePrompts = [
  { description: 'Main colors', prompt: 'Identify the main colors and objects in the image.' },
  { description: 'Change brightness/contrast', prompt: 'Change the image with filters, brightness/contrast adjustments.' },
  { description: 'Resize Image', prompt: 'Resize the image to a specified pixel dimension while keeping its shape the same.' },
  { description: 'Read Metadata', prompt: "Look at the image's metadata like when it was created or last changed." },
  { description: 'Aspect Radio Data', prompt: "Figure out the image's aspect ratio." },
  { description: 'Compare Images', prompt: 'Compare this image with another one for similarities or differences.' },
  { description: 'Change Color Scheme', prompt: "Change the image's color scheme from RGB to grayscale." },
  { description: 'Break down the image', prompt: 'Break the image down into its separate parts or objects.' },
  { description: 'Find Edges', prompt: 'Use edge detection to find the boundaries in the image.' },
  { description: 'Examine Texture', prompt: "Examine the image's texture for use in image processing tasks." },
  { description: 'Resize Image', prompt: 'Resize the image to specific dimensions (insert pixels here).' },
  { description: 'Crop for vertical', prompt: 'Crop this image to a vertical format of 1080x1920.' },
  { description: 'Crop for square', prompt: 'Crop this image to a square format of 1080x1080.' },
  { description: 'Crop without distortion', prompt: 'Crop the image without distorting its aspect ratio.' },
  { description: 'Convert to black and white', prompt: 'Convert this image to black and white.' },
  { description: 'Enhance Resolution', prompt: 'Enhance the image quality using sharpening techniques.' },
  { description: 'Apply filters', prompt: 'Apply artistic effects to the image (such as oil painting effect).' },
  { description: 'Add text', prompt: 'Overlay text onto the image.' },
  { description: 'Rotate or Flip', prompt: 'Flip or rotate the image.' },
  { description: 'Watermark', prompt: 'Apply a watermark to the image for copyright protection.' },
  { description: 'Transform into color palette', prompt: 'Transforms this photos into a color palette and export a PNG.' }
];

const fileTypeConversionPrompts = [
  { description: 'Convert to MP3', prompt: 'Change this Song to MP3' },
  { description: 'Convert to SRT', prompt: 'Convert this text to SRT file (subtitles)' },
  { description: 'WAV to MP3', prompt: 'Convert this WAV file to MP3' },
  { description: 'PNG to JPEG', prompt: 'Convert PNG to JPEG' },
  { description: 'MP4 to GIF', prompt: 'Covert this MP4 into a GIF' }
];

const codeAnalysisPrompts = [
  { description: 'Explain it', prompt: 'Can you explain this code?' },
  { description: 'Key Purpose', prompt: "What's the key purpose for this code?" },
  { description: 'Suggest improvements', prompt: 'Can you review the code and suggest improvements?' },
  { description: 'Extract Functions', prompt: 'Extract all the function definitions from the code.' },
  { description: 'List Modules', prompt: 'Find and list all the imported modules in the code.' },
  { description: 'Fun Functions', prompt: 'Run specific functions or methods in the code (if applicable).' },
  { description: 'Find Errors', prompt: 'Analyze the code for potential errors or inefficiencies.' },
  { description: 'Modify', prompt: 'Modify the code (e.g., implement a new function, adjust existing logic).' },
  { description: 'Explain specific sections', prompt: 'Explain what specific sections or lines of code are doing.' },
  { description: 'Test it', prompt: 'Test the code with different input parameters.' },
  { description: 'Create Flowchart', prompt: 'Create a flowchart or diagram to visualize the code execution.' },
  { description: 'Explain any class', prompt: 'Identify and explain any classes and their methods in the code.' },
  { description: 'Check against guidelines', prompt: 'Check if the code follows PEP8 style guidelines.' },
  { description: 'Find potential Errors', prompt: 'Find the lines of code that are most likely to throw exceptions.' },
  { description: 'Find security issues', prompt: 'Identify any potential security issues in the code.' },
  { description: 'Identify dependencies', prompt: 'Identify the dependencies of this script.' },
  { description: 'Give time estimate', prompt: 'Provide an estimate of how long the script will run with certain inputs.' },
  { description: 'Suggest improvements', prompt: 'Suggest ways to optimize or refactor the code for better performance or readability.' },
  { description: 'Create a test', prompt: 'Create a unit test for a specific function or method.' },
  { description: 'Identify main algorithm', prompt: 'Identify the main algorithm(s) used in the code and explain how they work.' },
  { description: 'Explain structure', prompt: 'Explain any complex data structures used in the code.' },
  { description: 'Identify any potential multithreading', prompt: 'Identify any potential multithreading or multiprocessing opportunities.' }
];

export const prompts = {
  'Analyzing Datasets': analyzingDatasetsPrompts,
  'Analyzing Financial Data': analyzingFinancialPrompts,
  'Image Editing & Alteration': ImagePrompts,
  'File Type Conversion': fileTypeConversionPrompts,
  'Code Analysis': codeAnalysisPrompts
};
