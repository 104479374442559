<template>
  <div class="h-full">
    <NuxtLayout>
      <NuxtPage></NuxtPage>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
	import { useInitApp } from './composables/useInitApp';
	useInitApp();
</script>

<style lang="less">

</style>
