import revive_payload_client_4sVQNw7RlN from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_03YjkxYbK5 from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_client_UYiXMU8ZyN from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_tbFNToZNim from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/.nuxt/unocss.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/.nuxt/element-plus-injection.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import aos_client_rDSYU13oOX from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/plugins/aos.client.ts";
import global_client_gYgfR4od5M from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/plugins/global.client.ts";
import page_progress_uiGXbUVfEF from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/plugins/page-progress.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_03YjkxYbK5,
  plugin_client_UYiXMU8ZyN,
  plugin_tbFNToZNim,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  unocss_MzCDxu9LMj,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  aos_client_rDSYU13oOX,
  global_client_gYgfR4od5M,
  page_progress_uiGXbUVfEF
]