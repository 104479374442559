export default {
  mounted (el: any, binding: any) {
    const video = el as HTMLVideoElement;
    const source = video.querySelector('source') as HTMLSourceElement;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          video.poster = binding.value;
          source.src = binding.value;
          observer.unobserve(video);
          video.load();
        }
      });
    });

    observer.observe(video);
  }
};
