const mapUrl = {
  'chatgpt-prompts-for-Design': 'chatgpt-prompts-for-designers',
  'chatgpt-prompts-for-General': 'general-chatgpt-prompts',
  'chatgpt-prompts-for-Music': 'chatgpt-music-prompts',
  'chatgpt-prompts-for-Language': 'chatgpt-prompts-for-language-learners',
  'chatgpt-prompts-for-Fun': 'fun-chatgpt-prompts',
  'chatgpt-prompts-for-IT': 'chatgpt-it-prompts',
  'chatgpt-prompts-for-Email': 'chatgpt-prompts-for-email-marketing',
  'chatgpt-prompts-for-Sales': 'chatgpt-prompts-for-sales',
  'chatgpt-prompts-for-Social%20Media': 'chatgpt-prompts-for-social-media',
  'chatgpt-prompts-for-Health': 'chatgpt-prompts-for-health',
  'chatgpt-prompts-for-Business': 'chatgpt-prompts-for-business',
  'chatgpt-prompts-for-SEO': 'chatgpt-prompts-for-seo',
  'chatgpt-prompts-for-Teaching': 'chatgpt-prompts-for-teaching',
  'chatgpt-prompts-for-Speech': 'chatgpt-prompts-for-speech',
  'chatgpt-prompts-for-Paid%20Ads': 'chatgpt-prompts-for-paid-ads'
};

const blogPathMap = [
  'best-chatgpt-prompts-for-resume',
  'best-chatgpt-prompts-for-cover-letter',
  'best-chatgpt-prompts-for-marketer',
  'best-chatgpt-prompts-for-e-commerce',
  'chatgpt-prompts-for-content-creation',
  'chatgpt-prompts-for-twitter-thread-ideas',
  'chatgpt-prompts-for-affiliate-marketing',
  'chatgpt-prompts-for-pr',
  'chatgpt-prompts-for-youtube-ad-scripts',
  'chatgpt-prompts-for-youtube-video-ideas',
  'chatgpt-prompts-for-instagram-story-ideas',
  'chatgpt-prompts-for-translators',
  'chatgpt-prompts-for-ppc',
  'chatgpt-prompts-for-games',
  'chatgpt-prompts-for-ux-design',
  'chatgpt-prompts-for-trading-strategy',
  'chatgpt-prompts-for-data-science',
  'chatgpt-prompts-for-writers',
  'chatgpt-prompts-for-email-campaigns',
  'chatgpt-prompts-for-education',
  'chatgpt-prompts-for-healthcare',
  'chatgpt-prompts-for-food-and-cooking',
  'best-chatgpt-prompts-for-pitch-decks',
  'best-chatgpt-virtual-assistant-prompts',
  'chatgpt-prompts-for-business-plans',
  'best-chatgpt-prompts-for-press-releases',
  'best-chatgpt-prompts-for-branding',
  'chatgpt-prompts-for-facebook-ads',
  'best-chatgpt-prompts-for-hr',
  'best-chatgpt-prompt-for-job-interview-preparation',
  'best-chatgpt-prompts-for-event-planning',
  'chatgpt-prompts-for-product-descriptions',
  'chatgpt-prompts-for-taglines-and-slogans',
  'best-chatgpt-prompts-for-partner-managers',
  'best-chatgpt-prompts-for-linkedin',
  'chatgpt-prompts-for-proofreading',
  'chatgpt-prompts-for-localization',
  'best-chatgpt-prompts-for-e-commerce-seo',
  'best-chatgpt-prompts-for-lead-generation',
  'chatgpt-prompts-for-mobile-marketing',
  'chatgpt-prompts-for-customer-service',
  'chatgpt-prompts-for-analytics',
  'sample-chatgpt-prompts-for-financial-advisors',
  'chatgpt-prompts-for-web-development',
  'chatgpt-prompts-for-cold-dm-ideas',
  'chatgpt-prompts-for-influencer-marketing',
  'chatgpt-prompts-for-cold-email-ideas',
  'best-chatgpt-prompts-for-leaders',
  'best-chatgpt-prompts-for-market-research',
  'best-chatgpt-prompts-for-project-managers',
  'chatgpt-prompts-for-data-entry-to-streamline-your-work',
  'chatgpt-prompts-for-lawyers-strengthen-your-case',
  '10-chatgpt-prompts-for-productivity-to-20x-your-skills',
  'chatgpt-prompts-for-consulting',
  'chatgpt-prompts-for-technical-writing-to-boost-your-work',
  '20-chatgpt-prompts-for-bloggers',
  'using-chatgpt-prompts-for-case-studies-10x-faster',
  'chatgpt-prompts-for-academic-writing',
  'chatgpt-prompts-for-accountants',
  'best-chatgpt-prompts-for-career',
  'best-chatgpt-prompts-for-local-seo',
  'best-chatgpt-prompts-for-content-editing',
  'best-chatgpt-prompts-for-customer-segmentation',
  'chatgpt-prompts-for-recruiters',
  'chatgpt-prompts-for-screenwriting',
  'chatgpt-prompts-for-instructional-designers',
  'chatgpt-prompts-for-business-consulting',
  'using-chatgpt-prompts-to-create-an-online-course',
  'chatgpt-prompts-for-business-videos',
  'chatgpt-prompts-for-book-writing',
  'best-chatgpt-prompts-for-artists',
  'best-chatgpt-prompts-for-engineering',
  'best-chatgpt-prompts-for-powerpoint',
  'chatgpt-prompts-for-students',
  'chatgpt-prompts-for-amazon-sellers',
  'chatgpt-prompts-for-banking',
  'chatgpt-prompts-for-business-ideas',
  'best-chatgpt-prompts-for-dropshipping',
  'best-chatgpt-prompts-for-film-makers',
  'best-chatgpt-prompts-landing-pages',
  'best-chatgpt-prompts-for-charities',
  'chatgpt-prompts-for-podcasters',
  'chatgpt-crypto-prompts',
  'chatgpt-prompts-for-cybersecurity',
  'chatgpt-prompts-for-doctors',
  'chatgpt-prompts-for-translation',
  'chatgpt-prompts-for-parents',
  'chatgpt-alternatives',
  'chatgpt-prompts-for-making-money',
  'chatgpt-prompts-for-meal-planning',
  'chatgpt-prompts-for-medical-students',
  'chatgpt-prompts-for-english-learning',
  'chatgpt-prompts-for-fitness',
  'chatgpt-prompts-for-learning-python',
  'chatgpt-prompts-for-python',
  'chatgpt-prompts-for-nurses',
  'chatgpt-jailbreak-prompts',
  'how-to-use-chatgpt-to-write-an-essay',
  'who-owns-chatgpt',
  'what-does-chatgpt-stand-for',
  'how-many-questions-can-you-ask-chatgpt-in-an-hour',
  'does-chatgpt-save-data',
  'can-you-use-chatgpt-to-write-essays',
  'can-chatgpt-write-code',
  'how-to-upload-resume-to-chatgpt',
  'what-does-gpt-stand-for-in-chatgpt',
  'is-chatgpt-plagiarism',
  'can-canvas-detect-chatgpt',
  'how-to-get-chatgpt-plus',
  'chatgpt-prompts-for-essay-writing',
  'chatgpt-prompts-for-literature-review',
  'chatgpt-prompts-for-keyword-research',
  'is-chatgpt-plus-worth-it',
  'is-chatgpt-plus-worth-it-reddit',
  'why-does-chatgpt-need-my-phone-number',
  'is-chatgpt-down',
  'how-to-tell-if-a-paper-was-written-by-chatgpt',
  'how-to-get-around-chatgpt-capacity-limitations',
  'how-companies-are-using-chatgpt',
  'how-many-questions-can-i-ask-chatgpt',
  'how-does-chatgpt-work-on-reddit',
  'can-chatgpt-predict-the-stock-market',
  'can-chatgpt-create-images',
  'how-to-learn-chatgpt',
  'how-to-download-chatgpt-on-iphones',
  'is-chatgpt-4-free',
  'how-many-pages-can-chatgpt-write',
  'can-chatgpt-do-accounting',
  'what-is-the-best-chatgpt-app-for-iphone-2',
  'why-isnt-chatgpt-working-for-me',
  'who-created-chatgpt',
  'how-much-data-was-chatgpt-trained-on',
  'how-to-get-around-chatgpt-filters',
  'is-chatgpt-supervised-or-unsupervised',
  'is-chatgpt-original-content',
  'how-to-jailbreak-chatgpt-4',
  'how-long-can-chatgpt-prompts-be',
  'how-to-download-chatgpt',
  'is-chatgpt-ai-or-ml',
  'how-to-use-chatgpt-for-data-analysis',
  'how-can-you-tell-if-something-is-written-by-chatgpt',
  'how-to-remove-highlight-from-chatgpt',
  'how-to-get-around-chatgpt-restrictions',
  'how-chatgpt-hijacks-democracy',
  'how-to-uncensor-chatgpt',
  'can-i-use-chatgpt-for-commercial-use',
  'how-do-you-pronounce-chatgpt',
  'how-to-use-chatgpt-when-at-capacity',
  'can-you-use-chatgpt-to-check-for-plagiarism',
  'how-to-get-chatgpt-to-continue-writing',
  'can-chatgpt-rewrite-my-resume',
  'can-chatgpt-4-generate-images',
  'how-to-delete-chatgpt-account',
  'how-does-chatgpt-make-money',
  'do-you-need-an-account-to-use-chatgpt',
  'can-chatgpt-write-lesson-plans',
  'does-snapchat-ai-use-chatgpt',
  'why-is-chatgpt-slow-and-explore-best-chatgpt-alternatives',
  'when-will-chatgpt-plugins-be-available',
  'how-to-use-code-interpreter',
  'can-chatgpt-be-detected',
  'does-chatgpt-plagiarize',
  'can-chatgpt-code-be-detected',
  'pygmalion-ai',
  'how-to-make-chatgpt-undetectable',
  'can-you-connect-chatgpt-to-the-internet',
  'conch-ai',
  'deepswap',
  'best-custom-gpts',
  'the-best-gpts',
  'resoomer-ai',
  'how-to-get-rid-of-the-grey-background-from-chatgpt',
  'how-to-create-custom-gpts',
  'how-do-gpts-work',
  'silly-tavern-ai',
  'inferkit',
  'grok-ai',
  'dezgo-ai',
  'tome-ai-when-ai-is-coming-for-presentation'
];

export default defineNuxtRouteMiddleware((to, from) => {
  // redirect
  const headers = useRequestHeaders();
  if (['www.arvin.chat', 'www.tryarvin.com', 'tryarvin.com'].includes(headers.host)) {
    return navigateTo(`https://arvin.chat${to.fullPath}`, { external: true, redirectCode: 301 });
  }
  if (to.path === '/contact') {
    return navigateTo('https://arvin-web.zendesk.com/hc/en-us/requests/new', { external: true, redirectCode: 301 });
  }

  const path = to.path.split('/');

  if (blogPathMap.includes(path[1])) {
    return navigateTo(`https://arvin.chat/blog${to.fullPath}`, { external: true, redirectCode: 301 });
  }

  if (path.length < 3) {
    return;
  }

  const index = Object.keys(mapUrl).indexOf(path[2]);
  if (index === -1) {
    return;
  }
  const value = Object.values(mapUrl)[index];
  const newPath = to.path.replace(path[2], value);
  return navigateTo(newPath, { redirectCode: 301 });
});
