import CryptoJS from 'crypto-js';
// import ChatDrawer from '~/components/chat-drawer/index.vue';
import { isObject } from '@vueuse/core';
import { getFeatCost, getUserAsset } from '~/apis/chat';
import { registerDeviceId } from '~/apis/user';

/**
 * Timestamp converted to how long ago format
 * @param timeString  just like this: 2023-07-12T01:11:28.916546+00:00'
 */
export const calcTimeAgo = (timeString: string | number) => {
  let timestamp = new Date(timeString).getTime();
  const now = new Date().getTime();
  const diff = now - timestamp;
  const oneMinute = 60 * 1000;
  const oneHour = oneMinute * 60;
  const oneDay = oneHour * 24;
  let result;
  if (diff < oneMinute) {
    return 'a few seconds ago';
  } else if (diff < oneHour) {
    result = Math.floor(diff / oneMinute);
    return result > 1 ? `${result} minutes ago` : 'a minute ago';
  } else if (diff < oneDay) {
    result = Math.floor(diff / oneHour);
    return result > 1 ? `${result} hours ago` : 'an hour ago';
  } else if (diff < oneDay * 2) {
    result = Math.floor(diff / oneDay);
    return result > 1 ? `${result} days ago` : 'a day ago';
  } else {
    let date = new Date(timestamp);
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day = String(date.getDate()).padStart(2, '0');
    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');
    let formattedTime = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedTime;
  }
};

export function formatDateTime (timestamp: number) {
  if (!timestamp) { return ''; }
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formatNumber = (num) => {
    return num.toString().padStart(2, '0');
  };
  const dateTimeString = `${year}-${formatNumber(month)}-${formatNumber(day)} ${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`;

  return dateTimeString;
}
// Convert timestamp to  English time format eg:3rd May 2023
// export function formatDate(time: number, needDay?: boolean) {
//   let date = new Date(time);
//   let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Spt', 'Oct', 'Nov', 'Dec'];
//   let suffix = ['st', 'nd', 'rd', 'th'];

//   let year = date.getFullYear();
//   let month = monthArr[date.getMonth()];
//   if (needDay) {
//     let days = date.getDate();
//     let day = '';

//     if (days % 10 < 1 || days % 10 > 3) {
//       day = String(days) + suffix[3];
//     } else if (days % 10 === 1) {
//       day = String(days) + suffix[0];
//     } else if (days % 10 === 2) {
//       day = String(days) + suffix[1];
//     } else {
//       day = String(days) + suffix[2];
//     }
//     return day + ' ' + month + ' ' + year;
//   }
//   return month + ' ' + year;
// }

function formatAMPM (date: Date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours || 12; // 0 in the 12-hour clock means 12
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ampm;
  return strTime;
}

// Convert timestamp to  English time format eg: Feb 1st, 11:30 am
export function formatDate (date: Date) {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const monthIndex = date.getMonth();
  const month = months[monthIndex];
  const day = date.getDate();
  let suffix = '';
  switch (day) {
    case 1:
    case 21:
    case 31:
      suffix = 'st';
      break;
    case 2:
    case 22:
      suffix = 'nd';
      break;
    case 3:
    case 23:
      suffix = 'rd';
      break;
    default:
      suffix = 'th';
      break;
  }
  return month + ' ' + day + suffix + ', ' + formatAMPM(date);
}

/**
 * encode HTML
 * @param source
 */
export const encodeHTML = (source: string) => {
  return source
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;');
};

export async function copyClipboard (target: string) {
  try {
    await navigator.clipboard.writeText(target);
    console.info('Text copied to clipboard');
  } catch (err) {
    const textArea = document.createElement('textarea');
    textArea.value = target;
    textArea.style.position = 'absolute';
    textArea.style.opacity = '0';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      document.execCommand('copy') ? res(true) : rej();
      textArea.remove();
    });
  }
}

/**
 * parse img url has blank space
 * @param url
 * @returns
 */
export const parseImgUrl = (url: string) => {
  if (!url) { return ''; }
  return '"' + String(url) + '"';
};

/**
 * get the length of plain text in rich text
 * @param richText
 * @returns
 */
export const getPlainTextLen = (richText: string) => {
  return richText
    .replace(/<[^>]+>/g, '')
    .replace(/ |\n|\r\n|&nbsp;|&ensp;/g, '')
    .replace(/[a-zA-Z]&#7([6-7][0-9]);/g, ' ')
    .replace(/&([a-z]{2,5}|#[0-9]{1,6});/g, ' ')
    .length;
};

export const arrAt = (arr: any[], index: number) => {
  if (!arr.length) { return null; }
  return arr[(index % arr.length + arr.length) % arr.length];
};

export function getPlatform () {
  const agent = process.client ? navigator.userAgent.toLowerCase() : '';
  let os = '--';
  if (
    agent.includes('win32') ||
    agent.includes('wow32') ||
    agent.includes('win64') ||
    agent.includes('wow64')
  ) { os = 'Windows'; }

  const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
  if (isMac) { os = 'MacOS'; }

  return os;
}

export function randomUUID (randomLength = 8) {
  return Number(Math.random().toString().substring(2, randomLength) + Date.now()).toString(36);
}

export function deepClone (target: Object) {
  if (!target || typeof target !== 'object') {
    return target;
  }
  return JSON.parse(JSON.stringify(target));
}

export function getArvinSessionToken () {
  const tokenInfo = getUserInfo().userArvinToken;
  if (!tokenInfo && localStorage.getItem('arvin_session_token')) {
    try {
      const token = JSON.parse(localStorage.getItem('arvin_session_token') || '{}').token;
      addUserInfoAttr('userArvinToken', token);
      localStorage.removeItem('arvin_session_token');
      return token;
    } catch (error) {
      return '';
    }
  }
  return tokenInfo || '';
}

export function removeLocalStorage () {
  const config = useNuxtApp().$arvinConfig as any;
  const userSessionKey = `${config.storageKey}user_session`;
  localStorage.removeItem('arvinAssetInfo');
  localStorage.removeItem('bp_session_token');
  localStorage.removeItem('arvin_session_token');
  localStorage.removeItem(`${config.storageKey}ap_switch`);
  localStorage.removeItem(userSessionKey);
  localStorage.removeItem('showDownloadDialog');
  localStorage.removeItem('showSubscribeTrialDialog');
  localStorage.removeItem('showDownloadTips');
  localStorage.removeItem('arvin_choose_plan');
  localStorage.removeItem('is_login_arvin_second');
  deleteInviterCode();
}

// Set email in localStorage
export function setUserInfo (info: unknown) {
  const config = useNuxtApp().$arvinConfig as any;
  const userSessionKey = `${config.storageKey}user_session`;
  localStorage.setItem(userSessionKey, JSON.stringify(info));
}

export function addUserInfoAttr (key: string, value: any) {
  let info = getUserInfo();
  info[key] = value;
  const config = useNuxtApp().$arvinConfig as any;
  const userSessionKey = `${config.storageKey}user_session`;
  localStorage.setItem(userSessionKey, JSON.stringify(info));
}

export function getUserInfo () {
  const config = useNuxtApp().$arvinConfig as any;
  const userSessionKey = `${config.storageKey}user_session`;
  const info = localStorage.getItem(userSessionKey);
  if (info) {
    return JSON.parse(info);
  }
  return {};
}

// Set bp_session_token in the localStorage
export function setBpSessionToken (token: string) {
  localStorage.setItem('bp_session_token', token);
}

export function getBpSessionToken () {
  const token = localStorage.getItem('bp_session_token');
  if (token) {
    return token;
  }
  return '';
}

export function getInviterCode () {
  return localStorage.getItem('arvin_invite_code');
}

export function deleteInviterCode () {
  return localStorage.removeItem('arvin_invite_code');
}

export function getIsLoginSecond () {
  return localStorage.getItem('is_login_arvin_second');
}

export function setIsLoginSecond (str: string) {
  if (str === '') {
    localStorage.removeItem('is_login_arvin_second');
  } else {
    localStorage.setItem('is_login_arvin_second', str);
  }
}

export function getStorage (key: string, expireTime: number, unit: number = 60 * 1000 * 60 * 24) {
  const data = localStorage.getItem(key);
  if (!data) {
    return '';
  }
  try {
    const dataObj = JSON.parse(data);
    const now = Date.now();
    const diffTime = Math.round(
      (now - dataObj.created_at) / unit
    );
    console.log(diffTime);
    if (diffTime < expireTime || expireTime === -1) {
      return dataObj.content;
    } else {
      return '';
    }
  } catch {
    return '';
  }
}

export function setStorage (key: string, content: any) {
  const dataInfo = {
    content,
    created_at: Date.now()
  };
  localStorage.setItem(key, JSON.stringify(dataInfo));
}

export const trackEvent = (category: string, action: string, name?: string, value?: number): void => {
  // @ts-ignore
  if (window._paq) {
    // @ts-ignore
    window._paq.push(['trackEvent', category, action, name, value]);
  } else {
    console.warn('can not found window._paq');
  }
};

export const downloadArvin = (type = '') => {
  const runtimeConfig = useRuntimeConfig();
  const { googleDownloadUrl, edgeDownloadUrl } = runtimeConfig.public;

  const browserName = getBrowserName();
  if (!type) {
    type = browserName === 'Edge' ? 'edge' : 'google';
  }
  const downloadUrl = type === 'google' ? googleDownloadUrl : edgeDownloadUrl;
  window.open(downloadUrl, '_blank');
};

export const linkToStore = (paramsString = '') => {
  const runtimeConfig = useRuntimeConfig();
  const { googleDownloadUrl, edgeDownloadUrl } = runtimeConfig.public;

  const browserName = getBrowserName();
  const downloadUrl = browserName === 'Edge' ? edgeDownloadUrl : googleDownloadUrl;
  console.log('downloadUrl', downloadUrl + paramsString);
  window.open(downloadUrl + paramsString, '_self');
  // window.location.href= downloadUrl + paramsString;
};

// export const openChatPanel = (type: string, prompt: string, chatDrawer: InstanceType<typeof ChatDrawer>) => {
export const openChatPanel = (type: string, prompt: string, openDownloadDialog: () => void) => {
  // const token = getArvinSessionToken();
  // if (!token) {
  //   const path = window.location.pathname + window.location.search;
  //   navigateTo(`/user/login?next=${path}`);
  //   return;
  // }
  reportEvent('Web_Prompts_Try_Click', { module_type: type });

  if (document && document.getElementById('arvinComponent')) {
    window.postMessage(
      {
        type: 'tryLibraryPrompt',
        prompt
      },
      '*'
    );
    // compatible version 1.0.2 plug-in logic without receiving a message
    const timer = setTimeout(() => {
      openDownloadDialog && openDownloadDialog();
      // chatDrawer.chatWithPrompt(prompt);
      // trackEvent('Button', 'click', 'show_sidebar');
    }, 1000);

    window.addEventListener('message', function (event) {
      if (event.data === 'received tryLibraryPrompt') {
        clearTimeout(timer);
      }
    });
    trackEvent('Button', 'click', 'open_extension');
  } else {
    openDownloadDialog && openDownloadDialog();
    // chatDrawer.chatWithPrompt(prompt);
    // trackEvent('Button', 'click', 'show_sidebar');
  }
};

/**
 * Serial the specified script
 * serial load (asynchronous) to load them one by one, each loading after completion of loading the next
 * all load callback after the completion of the execution
 * @ param {Array | String} scripts specified to load the scripts
 * @ param {Function} the callback after the success callback Function
 * @ return {Array} all generated script element Array of objects
 */

export function asyncLoadScripts (scripts: string[] | string, callback: () => void) {
  if (typeof scripts !== 'object') {
    scripts = [scripts];
  }
  let HEAD =
    document.getElementsByTagName('head')[0] || document.documentElement;
  let res: any[] = [];
  let last = scripts.length - 1;
  // recursive
  let recursiveLoad = function (i: number) {
    res[i] = document.createElement('script');
    res[i].setAttribute('type', 'text/javascript');
    // Attach handlers for all browsers
    // asynchronous
    res[i].onload = res[i].onreadystatechange = function () {
      if (
        !(/* @cc_on!@ */ 0) ||
        this.readyState === 'loaded' ||
        this.readyState === 'complete'
      ) {
        this.onload = this.onreadystatechange = null;
        this.parentNode.removeChild(this);
        if (i !== last) {
          recursiveLoad(i + 1);
        } else if (typeof callback === 'function') {
          callback();
        }
      }
    };
    // synchronous
    res[i].setAttribute('src', scripts[i]);
    HEAD.appendChild(res[i]);
  };
  recursiveLoad(0);
}

export function postMessageEncrypt (data: unknown) {
  const key = '1234567890ABCDEF';
  let raw = JSON.stringify(data);
  const ciphertext = CryptoJS.AES.encrypt(raw, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB
  });
  return ciphertext.toString();
}

export function setPayPalLastSubId (subId: string) {
  if (subId === '') {
    localStorage.removeItem('paypal_last_subid');
  } else {
    localStorage.setItem('paypal_last_subid', subId);
  }
}
export function getPayPalLastSubId () {
  return localStorage.getItem('paypal_last_subid');
}

// Judge subscription status, return a valid subscription list
export function handleSubscriptionStatus (assets: StripeAsset[]) {
  if (!Array.isArray(assets) || !assets.length) { return []; }
  return assets.filter((asset) => {
    let { type, valid_seconds } = asset;
    return type === 'subscription' && valid_seconds > 0;
  });
}

export const formatFileSize = (size: number) => {
  if (size < 1024) {
    return size + 'B';
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(2) + 'KB';
  } else if (size < 1024 * 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + 'MB';
  } else {
    return (size / 1024 / 1024 / 1024).toFixed(2) + 'GB';
  }
};

export const isImg = (url: string) => {
  return /\.(png|jpg|jpeg|gif|svg|webp|ico|bmp)$/i.test(url);
};

export function getRandomElements (arr: any[], n: number) {
  const result = [];

  for (let i = 0; i < n; i++) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    result.push(arr[randomIndex]);
    arr.splice(randomIndex, 1);
  }

  return result;
}

export const getBrowserName = () => {
  let userAgent = '';
  if (process.client) {
    userAgent = navigator.userAgent;
  } else {
    const nuxtApp = useNuxtApp();
    userAgent = nuxtApp.ssrContext?.event.headers.get('user-agent') || '';
  }
  let browserName;
  if (userAgent.includes('Firefox')) {
    browserName = 'Firefox';
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    browserName = 'Opera';
  } else if (userAgent.includes('Trident') || userAgent.includes('MSIE')) {
    browserName = 'Internet Explorer';
  } else if (userAgent.includes('Edg')) {
    browserName = 'Edge';
  } else if (userAgent.includes('Chrome')) {
    browserName = 'Chrome';
  } else if (userAgent.includes('Safari')) {
    browserName = 'Safari';
  } else {
    browserName = 'Unknown';
  }
  return browserName;
};

export function getDeviceId () {
  let deviceId = window.localStorage.getItem('arvin_device_id');
  deviceId = deviceId || createDeviceID();
  return deviceId;
}

// arvin-uuid-time
export function createDeviceID () {
  if (!process.client) { return ''; }
  const uuid = randomUUID();
  const deviceId = window.localStorage.getItem('arvin_device_id');
  let newDeviceId = '';
  if (deviceId) {
    // only update the time
    newDeviceId = deviceId.split('-').slice(0, 2).join('-') + `-${Date.now()}`;
  } else {
    newDeviceId = `arvin-${uuid}-${Date.now()}`;
  }
  window.localStorage.setItem('arvin_device_id', newDeviceId);
  registerDeviceId();
  return newDeviceId;
}

export function importImg (path: string) {
  const url = new URL(`/assets/imgs/${path}`, import.meta.url).href;
  return url;
}

export function getAssetsSrc (path: string) {
  const assets = import.meta.glob('~/assets/imgs/**/*', {
    eager: true,
    import: 'default'
  });
  return assets['/assets/' + path] as string;
}

export function getOS (): string {
  let os = 'unknown';
  const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
  const userAgent = window.navigator?.userAgent;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.includes(platform) || /Android/.test(userAgent)) {
    os = 'Mac OS';
  } else if (iosPlatforms.includes(platform) || /iOS/.test(userAgent)) {
    os = 'iOS';
  } else if (windowsPlatforms.includes(platform) || /Windows/.test(userAgent)) {
    os = 'Windows';
  } else if (/Android/.test(platform) || /Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform) || /Linux/.test(userAgent)) {
    os = 'Linux';
  }
  return os;
}

export function getOSVersion (): string {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/Windows NT 6.2/i.test(userAgent)) {
    return 'Windows 8';
  }
  if (/Windows NT 6.1/i.test(userAgent)) {
    return 'Windows 7';
  }
  if (/Windows NT 6.0/i.test(userAgent)) {
    return 'Windows Vista';
  }
  if (/Windows NT 5.1/i.test(userAgent)) {
    return 'Windows XP';
  }
  if (/Mac OS X 10_[0-9_]+/i.test(userAgent)) {
    return userAgent.match(/Mac OS X 10_[0-9_]+/i)[0].replace('_', '.');
  }
  if (/Android [0-9\.]+/i.test(userAgent)) {
    return userAgent.match(/Android [0-9\.]+/i)[0];
  }
  if (/Windows NT 10.0/i.test(userAgent)) {
    // if (browserHasWindows11Feature && browserHasWindows11Feature()) {
    //   return 'Windows 11';
    // }
    return 'Windows 10';
  }
  // Add more OS version detections as needed.

  return 'unknown';
}

export function mailToContact (email = 'contact@arvin.chat') {
  window.open(`mailto:${email}`);
}

export function isMobileDevice (): boolean {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
}

export function capitalizeFirstLetter (str?: string) {
  if (!str) { return ''; }
  return str.replace(/^\w/, char => char.toUpperCase());
}

export function windowPostLoginInfo (message: { [key: string]: any }) {
  const runtimeConfig = useRuntimeConfig();
  const env = runtimeConfig.public.env as any;
  window.postMessage(
    {
      type: 'ArvinSessionToken',
      msg: postMessageEncrypt(message),
      // Agreement to communicate with plug-ins
      host: env === 'production' ? 'arvin.chat' : window.location.host,
      hideDrawer: true
    },
    '*'
  );
  window.postMessage({
    type: 'ArvinDeviceId',
    deviceId: getDeviceId()
  }, '*');
}

export const uppercaseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isFileTypeValid = (file: File, accept: string) => {
  if (!accept) {
    return true;
  }
  const accepts = accept.split(',');
  const fileType = file.type;
  const fileName = file.name;
  const valid = accepts.some((type) => {
    const reg = new RegExp(`${type.replace('*', '.*')}$`);
    return reg.test(fileType) || reg.test(fileName);
  });
  return valid;
};

export const isFileSizeValid = (file: File, maxSize = 20) => {
  const size = file.size / 1024 / 1024;
  return size <= maxSize;
};

const downloadType: {
  [key: string]: string;
} = {
  text: 'text/plain',
  pdf: 'application/pdf',
  zip: 'application/zip',
  csv: 'text/csv',
  rar: 'application/rar',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  png: 'image/png',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  webp: 'image/webp',
  svg: 'image/svg+xml'
};
export const downloadBlob = (blob: Blob, fileName: string) => {
  return new Promise((resolve, reject) => {
    try {
      const ext = fileName.split('.').pop();
      const url = URL.createObjectURL(new Blob([blob], {
        type: downloadType[ext!] || 'application/octet-stream'
      }));
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
      a.remove();
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};
export function formatTimeStamp (timestamp: number, needSecond = false) {
  let date = new Date(timestamp);
  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, '0');
  let day = String(date.getDate()).padStart(2, '0');
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');
  let second = String(date.getSeconds()).padStart(2, '0');
  let formattedTime = `${year}-${month}-${day} ${hours}:${minutes} ${needSecond ? ':' + second : ''}`;
  return formattedTime;
}

export function judgeMobile () {
  let userAgent = '';
  if (process.client) {
    userAgent = navigator.userAgent;
  } else {
    const nuxtApp = useNuxtApp();
    userAgent = nuxtApp.ssrContext?.event.headers.get('user-agent') || '';
  }
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

export function timeInterval (timestamp: number, num: number) {
  let gap = (Date.now() - timestamp) / (1000 * 60 * 60);
  return Math.abs(gap) < num;
}

export function getConfigByStorage<T> (params: any): Promise<T> {
  let { key, time, callFunc } = params;
  const config = useNuxtApp().$arvinConfig as any;
  const storeKey = config.storageKey + key;

  return new Promise((resolve, reject) => {
    const target = JSON.parse(localStorage.getItem(storeKey) as string);
    if (target && target.saveTime && timeInterval(target.saveTime, time)) {
      resolve(target[key]);
    } else {
      callFunc().then((result: any) => {
        localStorage.setItem(storeKey, JSON.stringify({
            [key]: result,
            saveTime: Date.now()
        })
        );
        resolve(result);
      }).catch(reject);
    }
  });
}

// Inert request mechanism, use first, then update.
export function getConfigByStorageLazyFetch<T> (params: any): Promise<T> {
  let { key, time, callFunc } = params;
  const config = useNuxtApp().$arvinConfig as any;
  const storeKey = config.storageKey + key;

  return new Promise((resolve, reject) => {
    const target = JSON.parse(localStorage.getItem(storeKey) as string);
      if (target && target.saveTime && timeInterval(target.saveTime, time)) {
        resolve(target[key]);
      } else if (target && target.saveTime) {
        // Expired, but use it first, then update it.
        callFunc().then((result: any) => {
          localStorage.setItem(storeKey, JSON.stringify({
            [key]: result,
            saveTime: Date.now()
          })
          );
        }).catch(reject);
        resolve(target[key]);
      } else {
        callFunc().then((result: any) => {
          localStorage.setItem(storeKey, JSON.stringify({
            [key]: result,
            saveTime: Date.now()
          })
          );
          resolve(result);
        }).catch(reject);
      }
  });
}

export function getQueryParams (search?:string) {
  let params = {} as any;
  search = search || window.location.search;
  let pairs = (search.substring(1)).split('&');
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return params;
}

interface PromiseWithState<T> {
  (...args: any[]): Promise<T>;
}

/**
 * Returns a new function that wraps the passed asynchronous function and ensures that only one invocation is in progress at a time.
 *
 * @param {(...args: any[]) => Promise<T>} asyncFunc - The asynchronous function to be wrapped.
 * @return {PromiseWithState<T>} - The wrapped function that ensures only one invocation is in progress at a time.
 */
export function promiseWithState<T> (asyncFunc: (...args: any[]) => Promise<T>): PromiseWithState<T> {
  let state: 'idle' | 'pending' | 'resolved' | 'rejected' = 'idle'; // Initial state is 'idle'
  let result: Promise<T> | null = null; // Used to cache the result

  return (...args: any[]) => {
    if (state === 'pending') {
      // If the current state is 'pending', it means a request is in progress, so return the existing Promise
      return result!;
    }

    state = 'pending'; // Update the state to 'pending'
    result = asyncFunc(...args); // Call the passed asynchronous function

    return result.then(
      (data) => {
        state = 'resolved'; // Update the state to 'resolved'
        return data;
      },
      (error) => {
        state = 'rejected'; // Update the state to 'rejected'
        throw error;
      }
    );
  };
}

/**
 * Triggers an event once per day or month.
 * @param eventName the name of the event
 * @param interval 'day' or 'month'
 * @param eventFunction the function to be called when the event is triggered
 */
export function triggerEventOnce (eventName: string, interval: string, eventFunction: Function) {
  const currentDate = new Date();
  let lastTriggered: any = localStorage.getItem(eventName);

  if (lastTriggered) {
    lastTriggered = new Date(lastTriggered);
  } else {
    lastTriggered = new Date(0); // If no date is stored, set it to epoch
  }

  let shouldTrigger = false;

  switch (interval) {
    case 'day': {
       // Check if the current date is different from the last triggered date
       const lastDay = lastTriggered.toISOString().split('T')[0];
       const currentDay = currentDate.toISOString().split('T')[0];

       if (currentDay !== lastDay) {
         shouldTrigger = true;
       }
       break;
    }
    case 'month': {
      // Check if the current month is different from the last triggered month
      const lastMonth = lastTriggered.getFullYear() + '-' + (lastTriggered.getMonth() + 1);
      const currentMonth = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1);
      if (currentMonth !== lastMonth) {
        shouldTrigger = true;
      }
      break;
    }
  }

  if (shouldTrigger) {
    eventFunction(); // Call the event function
    localStorage.setItem(eventName, currentDate.toISOString().split('T')[0]); // Update the last triggered date in localStorage
  }
}

export function setDomRef (refObject: Ref) {
  return (el: any) => {
    refObject.value = el;
  };
}

export function formatUserPlan (plan?: string) {
  if (plan) {
    if (plan === 'ultra') {
      return 'Unlimited';
    }
    return capitalizeFirstLetter(plan);
  }
  return '';
}

export function formatPrice (item: Product) {
  if (item.type === 'month') { return item.price; }
  return ((item.price || 0) / 12).toFixed(2);
}
