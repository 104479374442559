import type { GenericAbortSignal } from 'axios';
import { get, post, put, specialHandle, del, tryChatSpecialHandle } from '@/hooks/request';
import { Feat } from '~/composables/useOptions';
import { useFeat } from '@/store/feat';
import { getConfigByStorage } from '~/composables/useUtils';

export function getUserAsset () {
  return get<UserAsset>({
    url: `/api/users/v2/asset`
  });
}

export function getFeatCost () {
  return get<FeatCost>({
    url: `/api/chat/feat_cost_v4`
  });
}

export function getShareChatList (share_id: string) {
  return get<ShareChatList>({
    url: `/api/chat/history/share_detail`,
    data: {
      share_id
    }
  });
}

export const getMyPromptList = () => {
  const config = useNuxtApp().$arvinConfig as any;
  let storeKey = config.storageKey + 'myprompt';
  return new Promise((resolve, reject) => {
    get({
      url: `/api/users/storage/${storeKey}`
    }).then((res) => {
      if (!res.error) {
        let value = res.storage.content_value;
        let realResult = JSON.parse(value);
        resolve(realResult);
      }
      if (res.error.message === 'item_not_found') {
        addMyPrompts(config.initUserPrompts)
          .then(data => resolve(data));
      }
    }).catch(reject);
  });
};

export function addMyPrompts (newMyPrompts: any[]) {
  const config = useNuxtApp().$arvinConfig as any;
  let storeKey = config.storageKey + 'myprompt';
  let param = {
    category: 1,
    content_key: storeKey,
    content_value: JSON.stringify(newMyPrompts)
  };
  return new Promise((resolve, reject) => {
    post({
      url: `/api/users/storage/${storeKey}`,
      data: param
    }).then((result: any) => {
      let value = result.storage.content_value;
      let realResult = JSON.parse(value);
      resolve(realResult);
    }).catch(reject);
  });
}

export function saveMyPrompts (newMyPrompts: any[]) {
  const config = useNuxtApp().$arvinConfig as any;
  let storeKey = config.storageKey + 'myprompt';
  let param = {
    category: 1,
    content_value: JSON.stringify(newMyPrompts)
  };
  return new Promise((resolve, reject) => {
    put({
      url: `/api/users/storage/${storeKey}`,
      data: param
    }).then((result: any) => {
      let value = result.storage.content_value;
      let realResult = JSON.parse(value);
      resolve(realResult);
    }).catch(reject);
  });
}

export function getChatHistoryList (query = '') {
  return get({
    url: `/api/chat/history`,
    data: {
      page: 1,
      page_size: 500,
      query
    }
  });
}

export function updateHistoryChat (data: any) {
  return put(
    {
      url: `/api/chat/history/topic`,
      data: {
        chat_id: data.chat_id,
        topic: data.topic
      }
    }
  );
}

export function deleteChatHistory (chat_id: string) {
  return new Promise((resolve, reject) => {
    del({
      url: `/api/chat/history?chat_id=${chat_id}`
    }).then((result: any) => {
      resolve(result);
    }).catch(reject);
  });
}

export function addChatHistory (data: any) {
  return post({
    url: `/api/chat/history/topic`,
    data
  });
}

export function addChatTopic (data: any) {
  return post(
    {
      url: `/api/chat/history/topic`,
      data
    }
  );
}

export function getAllFeat (lang = 'en') {
  return new Promise((resolve, reject) => {
    get(
      {
        url: `/api/prompts/feat`,
        data: {
          language: lang
        }
      }
    ).then((result: any) => {
      let featResult = result[lang];
      let realResult: any = {};

      featResult.forEach((item: any) => {
        realResult[item.id] = item.prompts_id;
      });
      resolve(realResult);
    }).catch(reject);
  });
}

export function sendChatStream (params: { promptKey: keyof typeof Feat, data: StreamChat, signalId?: GenericAbortSignal, onStreamResponse?: (data: ChatGTPMessage) => void }): Promise<ChatGTPMessage> {
  const { prompts } = useFeat();

  params.data.feat = params.promptKey;
  params.data.conversation.forEach((item) => {
    item.prompts_id = prompts[params.promptKey];
  });

  let lastLen = 0;

  return post<ChatGTPMessage>(
    {
      url: `/api/chat/stream`,
      data: params.data,
      isStream: true,
      headers: {
        responseType: 'stream',
        accept: 'text/event-stream'
      },
      signal: params.signalId,
      onDownloadProgress: ({ event }) => {
        const xhr = event.target;
        let { responseText } = xhr;
        const { data: realData } = specialHandle(responseText, lastLen);
        lastLen = responseText.split('\n\n').filter((item:any) => item).length;
        params.onStreamResponse && params.onStreamResponse(realData as ChatGTPMessage);
      }
    }
  );
}

export function sendChatTryStream (params: { promptKey: keyof typeof Feat, data: StreamTryChat, signalId?: GenericAbortSignal, onStreamResponse?: (data: ChatGTPMessage, code:any) => void }): Promise<ChatGTPMessage> {
  let lastLen = 0;
  return post<ChatGTPMessage>(
    {
      url: `/api/chat/try_stream`,
      data: params.data,
      isTryStream: true,
      headers: {
        responseType: 'stream',
        accept: 'text/event-stream'
      },
      signal: params.signalId,
      onDownloadProgress: ({ event }) => {
        const xhr = event.target;
        let { responseText } = xhr;
        const { data: realData, code } = tryChatSpecialHandle(responseText, lastLen);
        lastLen = responseText.split('\n\n').filter((item:any) => item).length;
        params.onStreamResponse && params.onStreamResponse(realData as ChatGTPMessage, code);
      }
    }
  );
}

export function getNoticeList () {
  return get({
    url: `/api/notice`
  });
}

export function readNotice (id: string) {
  return post({
    url: `/api/notice`,
    data: {
      id
    }
  });
}

export function getComposeConfig () {
  const config = useNuxtApp().$arvinConfig as any;
  return get({
    url: `/api/feat/config?version=${config.version}`
  });
}

export function getPresetPromptForAutoComplete (kw: string) {
  return new Promise((resolve, reject) => {
    get({
      url: `/api/prompts/list?query=${kw}&page_size=50`
    }).then((result: any) => {
      let data = result.map((item: any) => {
        return {
          name: '/' + item.title,
          content: item.prompt
        };
      });
      resolve(data);
    }).catch(reject);
  });
}

export function getToolsConfigBeta () {
  return get<{
    switch: {
      code_interpreter: boolean,
    }
  }>({
    url: `/api/feat/config/beta`
  });
}

export function getPromptList (params: PublicPromptParams) {
  return get({
    url: `/api/prompts/filter_by_tag`,
    data: params
  });
}

export function collectPrompt (id: string) {
  return post({
    url: `/api/prompts/user/star`,
    data: {
      id
    }
  });
}

export function unCollectPublicPrompt (id: string) {
  return post({
    url: `/api/prompts/user/unstar`,
    data: {
      id
    }
  });
}

export function getMyCollectStatus (id: string) {
  return get<boolean>({
    url: `/api/prompts/user/verify_star?id=${id}`
  });
}

export function getBatchCollectStatus (params: { ids: string }) {
  return get<any>({
    url: `/api/prompts/user/batch_verify_star`,
    data: params
  });
}

export const getGPTsDetail = (path_name : string) => {
  return get<GptsItem>({
    url: '/api/ai_gpts/public/gpts/detail?path=' + path_name
  });
};

export const feedbackGPTs = (data: {
  uuid: string,
  action: string,
  chat_id: string,
  content: string,
}) => {
  return post({
    url: '/api/feedback/gpts_chat',
    data
  });
};

export const createImg = (data: {
  init_image_url: string,
  mask_image?: string,
  feature: string,
  prompt?: string,
  background?: string,
  mode?: string,
  upscaling?: {
    target_width: number,
    target_height: number
  },
  extend_left?: number,
  extend_right?: number,
  extend_up?: number,
  extend_down?: number
}) => {
  return post({
    url: '/api/toolkit/paint',
    data,
    showError: false
  });
};

export function getInviteList () {
  return get<any>({
    url: `/api/users/invited/records?page_size=50&page_offset=0`
  });
}
export function getBotLists (query: any) {
  return get<any>({
    url: `/api/ai_tool/query_tool_list`,
    data: { ...query }
  });
}
export function getTagLists () {
  return get<any>({
    url: `/api/ai_tool/tool_tags`
  });
}

export function getBotDetail (id: string) {
  return get<any>({
    url: `/api/ai_tool/tool/${id}`
  });
}

export function getLogoPageBots (key: string): Promise<Record<string, string>> {
  return getConfigByStorage({
    key,
    callFunc: () => get<any>({
      url: `/api/feat/config/common?key=${key}`
    })
  });
}

export async function getPublicBotList (params?: { tag: string, query: string }) {
  let data = await getConfigByStorage({
    key: 'public_bot_list',
    time: 24,
    callFunc: () => get<{
      count: number,
      items: Toolkit[],
    }>({
      url: `/api/ai_gpts/public/list`,
      data: params
    })
  }) as {
    count: number,
    items: Bot[],
  };
  return data;
}
