import { signOut } from '~/apis/login';

export const logout = (user: StorageUserInfo, click_type = 'web_dashboard') => {
  signOut().then(() => {
    const message = {
      userId: user.userId
    };
    removeLocalStorage();
    reportEvent('User_SignOut_Success', {
      click_type
    });
    window.postMessage(
      {
        type: 'ArvinSessionLogout',
        msg: postMessageEncrypt(message)
      },
      '*'
    );
    navigateTo('/user/login');
  });
};

export const logoutConfirm = (user: StorageUserInfo, click_type = 'web_dashboard') => {
  reportEvent('User_SignOut_Click', {
    click_type
  });
  ElMessageBox.confirm('Are you sure to Sign Out?', 'Sign Out', {
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Sign Out',
    confirmButtonClass: 'button-danger',
    distinguishCancelAndClose: true
  }).then((action: any) => {
    if (action === 'confirm') {
      logout(user);
    }
  });
};
