interface UtmParams {
  media_source?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_id?: string;
}

const defaultUtmParams: UtmParams = {
  media_source: "Others",
  utm_source: "unknown",
  utm_medium: "unknown",
  utm_campaign: "unknown",
  utm_id: "unknown"
};

const UTM_LOCAL_KEY = 'utmParams';

// https://arvin.chat/webstore?utm_source=google&utm_medium=banner&utm_campaign=Arvin[…]6-search-ins&utm_id=2094970262&utm_term=click&utm_content=arvin
function getUtmParamsFromUrl(): UtmParams {
  const searchParams = new URLSearchParams(window.location.search);
  let referer = document.referrer, mediaSource='';
  if (referer.includes("youtube.com")) {
    mediaSource = 'YouTube'
  } else if (referer.includes("twitter.com")) {
    mediaSource = 'Twitter'
  } else if (referer.includes("facebook.com")) {
    mediaSource = 'Facebook'
  } else if (referer === "") {
    mediaSource = 'Direct'
  }
  return {
    media_source: mediaSource || undefined,
    utm_source: searchParams.get('utm_source') || undefined,
    utm_medium: searchParams.get('utm_medium') || undefined,
    utm_campaign: searchParams.get('utm_campaign') || undefined,
    utm_id: searchParams.get('utm_id') || undefined,
  };
}

function storeUtmParams(params: UtmParams): void {
  localStorage.setItem(UTM_LOCAL_KEY, JSON.stringify(params));
}

function getStoredUtmParams(defaultUtmParams?: UtmParams): UtmParams | null {
  const storedParams = localStorage.getItem(UTM_LOCAL_KEY);
  return storedParams ? JSON.parse(storedParams) : (defaultUtmParams || null);
}

const utmParams = ref<UtmParams | null>(null);
export function useUtmTracking() {

  // onMounted need to parent
  const initUTM = () => {
    utmParams.value = getStoredUtmParams();
    const urlUtmParams = getUtmParamsFromUrl();
    if (urlUtmParams.utm_source || urlUtmParams.utm_medium || urlUtmParams.media_source || urlUtmParams.utm_campaign || urlUtmParams.utm_id) {
      utmParams.value = urlUtmParams;
      storeUtmParams(urlUtmParams);
      reportEvent('Web_Entry_From_Promotion_Link');
    }
  }

  const reportAndClearStoredParams = async () => {
    const params = getStoredUtmParams();
    if (params) {
      // await reportUtmParams(params);
      localStorage.removeItem(UTM_LOCAL_KEY);
      utmParams.value = null;
    }
  };

  return {
    initUTM,
    utmParams,
    defaultUtmParams,
    getStoredUtmParams,
    reportAndClearStoredParams
  };
}