import validate from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/nuxt/dist/pages/runtime/validate.js";
import rewrite_45path_45global from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/middleware/rewritePath.global.ts";
import manifest_45route_45rule from "/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  rewrite_45path_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/jenkins/agent/workspace/Arvin/arvin-mainsite-develop/arvin_mainsite_workspace/middleware/auth.ts")
}