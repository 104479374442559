export default {
  mounted(el: HTMLElement, binding: { value: { axis: string; handle: string; boundary: string; clickHandle: () => void; }; }) {
    let startX = 0; let startY = 0; let initStartX = 0; let initStartY = 0;

    const { axis, handle, boundary, clickHandle } = binding.value;

    const dragHandle = (handle ? el.querySelector(handle) : el) as HTMLElement;
    const boundaryEl = boundary === 'body' ? document.body : document.querySelector(boundary) || null;

    dragHandle.style.cursor = 'grab';

    const dragMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      startX = e.clientX;
      startY = e.clientY;
      initStartX = e.clientX;
      initStartY = e.clientY;
      dragHandle.style.cursor = 'grabbing';
      document.addEventListener('mousemove', elementDrag);
      document.addEventListener('mouseup', stopDragging);
    };

    const elementDrag = (e: MouseEvent) => {
      e.preventDefault();
      const offsetX = startX - e.clientX;
      const offsetY = startY - e.clientY;
      startX = e.clientX;
      startY = e.clientY;
      let boundaryRect = boundaryEl
        ? boundary === 'body'
          ? {
            bottom: window.innerHeight,
            right: window.innerWidth,
            left: 0,
            top: 0
          }
          : boundaryEl.getBoundingClientRect()
        : null;

      if (axis === 'x') {
        if (boundaryRect) {
          const newRight = Math.max(
            boundaryRect.left + el.offsetWidth,
            Math.min(boundaryRect.right, el.offsetLeft + el.offsetWidth - offsetX)
          );
          el.style.right = boundaryRect.right - newRight + 'px';
        } else {
          el.style.right = el.offsetLeft + el.offsetWidth - offsetX + 'px';
        }
      } else if (axis === 'y') {
        if (boundaryRect) {
          const newBottom = Math.max(
            boundaryRect.top + el.offsetHeight,
            Math.min(boundaryRect.bottom, el.offsetTop + el.offsetHeight - offsetY)
          );
          el.style.bottom = boundaryRect.bottom - newBottom + 'px';
        } else {
          el.style.bottom = el.offsetTop + el.offsetHeight - offsetY + 'px';
        }
      } else {
        if (boundaryRect) {
          const newRight = Math.max(
            boundaryRect.left + el.offsetWidth,
            Math.min(boundaryRect.right, el.offsetLeft + el.offsetWidth - offsetX)
          );
          const newBottom = Math.max(
            boundaryRect.top + el.offsetHeight,
            Math.min(boundaryRect.bottom, el.offsetTop + el.offsetHeight - offsetY)
          );
          el.style.right = boundaryRect.right - newRight + 'px';
          el.style.bottom = boundaryRect.bottom - newBottom + 'px';
        } else {
          el.style.right = el.offsetLeft + el.offsetWidth - offsetX + 'px';
          el.style.bottom = el.offsetTop + el.offsetHeight - offsetY + 'px';
        }
      }
    };

    const stopDragging = (e: any) => {
      dragHandle.style.cursor = 'grab';
      document.removeEventListener('mousemove', elementDrag);
      document.removeEventListener('mouseup', stopDragging);

      if (Math.abs(e.clientY - initStartY) <= 3 && Math.abs(e.clientX - initStartX) <= 3) {
        clickHandle && clickHandle();
      }
    };

    dragHandle.addEventListener('mousedown', dragMouseDown);

    const cleanup = () => {
      dragHandle.removeEventListener('mousedown', dragMouseDown);
    };

    return cleanup;
  }
}