import axios, { type AxiosResponse } from 'axios';
import packageJson from '../../package.json';
import { getDeviceId } from '~/composables/useUtils';
import { araesBaseParams } from '~/composables/useSentry';

const service = axios.create({
  baseURL: ''
});

console.log('log [axios]', service.interceptors.request);
service.interceptors.request.use(
  async (config) => {
    const token = getArvinSessionToken();
    const { version } = packageJson;
    let deviceId = getDeviceId();
    if (token) { config.headers['x-arvin-authorization'] = token; }
    if (deviceId) { config.headers['x-arvin-device'] = deviceId; }
    if (version) { config.headers['x-arvin-version'] = version; }
    if (config.method === 'post') {
      try {
        const arvinIdentity = await araesBaseParams();
        config.headers['x-arvin-identity'] = arvinIdentity;
      } catch (error) {

      }
    }
    // console.log(`[${config.method}]:${config.baseURL}${config?.url} token=${token}`);
    return config;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

service.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    // console.log(`${response.status}: success`);
    if (response.status === 200) { return response; }
    throw new Error(response.status.toString());
  },
  (error) => {
    console.log(`${error.cause}`);
    return Promise.reject(error);
  }
);

export default service;
