import type { AxiosProgressEvent, AxiosRequestConfig, AxiosResponse, GenericAbortSignal } from 'axios';
import request from './axios';
import { ImageToolsErrorCode } from './errorCode';
import { removeLocalStorage, getArvinSessionToken } from '~/composables/useUtils';

export interface HttpOption {
  url: string
  data?: any
  method?: string
  headers?: any
  responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream'
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  signal?: GenericAbortSignal
  beforeRequest?: () => void
  afterRequest?: () => void,
  showSuccess?: boolean,
  showError?: boolean,
  isStream?: boolean
  isTryStream?: boolean

}

export interface Response<T = any> {
  data: T
  msg: string
  code: number,
  message?: string
}

// Special treatment session interface
export function specialHandle (str: string, lastLen = 0) {
  let data = [];
  // @ts-ignore
  data = str.split('\n\n').filter(item => item);
  try {
    // Every time there may be data from more than added a
    // So the length of data over time we as a benchmark, interception of data
    let sliceData = data.slice(lastLen);
    let content = '';
    let files: { filename: string, file_url: string }[] = [];
    if (sliceData.length > 0) {
      sliceData.forEach((chunk) => {
        if (chunk) {
          const { event, data: realData } = parseMessage(chunk);
          content += realData.data.content;
          files = [...files, ...(realData.data?.files || [])];
        }
      });
    }

    let lastChunk: any = arrAt(data, -2);
    if (arrAt(data, -1)) { lastChunk = arrAt(data, -1); }
    if (lastChunk) {
      lastChunk = parseMessage(lastChunk).data;
    }
    return {
      code: 20000,
      msg: '',
      data: {
        content,
        chat_id: lastChunk?.data.chat_id,
        is_finish: lastChunk.data.is_finish,
        is_code: lastChunk.data.is_code,
        files,
        status: lastChunk.data.status || 0
      }
    };
  } catch (e) {
    console.log(e);
    return {
      code: -1,
      data: {} as ChatGTPMessage,
      msg: 'CONVERSATION_ERROR'
    };
  }
}
export function tryChatSpecialHandle (str: string, lastLen = 0) {
  let data = [];
  // @ts-ignore
  data = str.split('\n\n').filter(item => item);
  try {
    // Every time there may be data from more than added a
    // So the length of data over time we as a benchmark, interception of data
    let sliceData = data.slice(lastLen);
    let content = '';
    let files: { filename: string, file_url: string }[] = [];
    let code = '';
    if (sliceData.length > 0) {
      sliceData.forEach((chunk) => {
        if (chunk) {
          const { event, data: realData } = parseMessage(chunk);
          code = realData.code;
          content += realData.content;
          files = [...files, ...(realData?.files || [])];
        }
      });
    }

    let lastChunk: any = arrAt(data, -2);
    if (arrAt(data, -1)) { lastChunk = arrAt(data, -1); }
    if (lastChunk) {
      lastChunk = parseMessage(lastChunk).data;
    }
    return {
      code,
      msg: '',
      data: {
        content,
        is_finish: lastChunk.data?.is_finish,
        files,
        status: lastChunk.data?.status || 0
      }
    };
  } catch (e) {
    console.log(e);
    return {
      code: -1,
      data: {} as ChatGTPMessage,
      msg: 'CONVERSATION_ERROR'
    };
  }
}

export function formatAiSearchResult (str: string, lastLen = 0) {
  let data = [];
  // @ts-ignore
  data = str.split('\n\n').filter(item => item);
  try {
    // Every time there may be data from more than added a
    // So the length of data over time we as a benchmark, interception of data
    let sliceData = data.slice(lastLen);
    let depth_study = '';
    let quick_answer = '';
    let related:string[] = [];
    let navigation:any[] = [];
    if (sliceData.length > 0) {
      sliceData.forEach((chunk) => {
        if (chunk) {
          const { event, data: realData } = parseMessage(chunk);
          depth_study += realData.data.depth_study || '';
          quick_answer += realData.data.quick_answer || '';
          if (realData.data.related) {
            related = [...related, ...realData.data.related];
          }
          if (realData.data.navigation) {
            navigation = [...navigation, ...realData.data.navigation];
          }
        }
      });
    }

    let lastChunk: any = arrAt(data, -2);
    if (arrAt(data, -1)) { lastChunk = arrAt(data, -1); }
    if (lastChunk) {
      lastChunk = parseMessage(lastChunk).data;
    }
    return {
      code: 20000,
      msg: '',
      data: {
        depth_study,
        quick_answer,
        navigation,
        related,
        item_id: lastChunk?.data.item_id,
        search_id: lastChunk?.data.search_id,
        // reference: lastChunk?.data.reference,
        is_finish: lastChunk.data.is_finish,
        is_code: lastChunk.data.is_code,
        status: lastChunk.data.status || 0
      }
    };
  } catch (e) {
    console.log(e);
    return {
      code: -1,
      data: {} as ChatGTPMessage,
      msg: 'CONVERSATION_ERROR'
    };
  }
}

export function parseMessage (message: string) {
  const lines = message.split('\n');
  const msg: any = {};
  for (const line of lines) {
    let { field, value } = parseLine(line);

    if (field === 'data') { value = JSON.parse(value); }

    msg[field] = value;
  }
  return msg;
}

function parseLine (line: string) {
  const pos = line.indexOf(': ');
  if (pos === -1) { throw new Error(`Can't find ': ' in line '${line}'`); }

  return {
    field: line.slice(0, pos),
    value: line.slice(pos + 2)
  };
}

const tokenError = [40003, 403];
let isLimitOut = false;
const specialUrl = '/api/pdfutil/convert';
export function http<T = any> (
  { url, data, method, headers, onDownloadProgress, onUploadProgress, signal, beforeRequest, afterRequest, showSuccess, showError, isStream, isTryStream, responseType }: HttpOption
): Promise<T> {
  const successHandler = (res: AxiosResponse<Response<T>>): T | Promise<any> => {
    if (url.includes(specialUrl)) {
      return res.data as any;
    }
    // Special treatment session interface
    if (isStream) {
      // @ts-ignore
      res.data = specialHandle(res.data);
    }
    if (isTryStream) {
      // @ts-ignore
      res.data = tryChatSpecialHandle(res.data);
    }

    // Error code 2 at the beginning of identity as a special business, can be dealt with according to the success
    if (res.data.code === 20000 || String(res.data.code)[0] === '2') {
      if (showSuccess) {
        ElMessage.success(res.data.msg);
      }
      return res.data.data;
    } if (res.data.code === 40407 || res.data.code === 40406 || res.data.code === 50101 || res.data.code === 50112) {
      return Promise.reject(res.data);
    } else if (tokenError.includes(res.data.code)) {
      console.log('token error');
      removeLocalStorage();
      window.location.pathname !== 'user/login' && navigateTo('/user/login');
      return Promise.reject(res.data);
    } else if ([40700, 40701, 40702].includes(res.data.code) && getArvinSessionToken()) {
      // pass
      const codeList: any = {
        40700: 'permanently',
        40701: ' 30 days',
        40702: ' 7 days'
      };
      removeLocalStorage();
      ElMessageBox.confirm(
        `<p>Your account has been suspended for ${codeList[res.data.code]}. Please contact <a class="cp c-#6165F6 hover:op-80" target="_blank"  to="mailto:contact@arvin.chat">contact@arvin.chat</a> support for assistance and further details.</p>`,
        'Account Suspended',
        {
          confirmButtonText: 'OK',
          type: 'warning',
          showCancelButton: false,
          dangerouslyUseHTMLString: true,
          center: true,
          showClose: false
        }
      ).then(() => {
        navigateTo('/user/login');
      });
    } else if (res.data.code === 40501 && !isLimitOut) {
      console.log('token kick');
      isLimitOut = true;
      removeLocalStorage();
      const msg = 'Login limit reached: this device has been logged out as another device connected. Log out from other devices or upgrade for more connections.';
      // ElMessage.error(msg);
      ElMessageBox.confirm(
        msg,
        'Warning',
        {
          confirmButtonText: 'OK',
          type: 'warning',
          showCancelButton: false,
          center: true,
          showClose: false
        }
      ).then(() => {
			  navigateTo('/user/login');
      }).finally(() => {
        removeLocalStorage();
        isLimitOut = false;
      });
      // return Promise.reject(res.data);
    } else {
      if (showError && process.client) {
        let msg = 'Login failed, please log in again.';
        ImageToolsErrorCode[res.data.code] && (msg = ImageToolsErrorCode[res.data.code]);
        ElMessage.error(tokenError.includes(res.data.code) || ImageToolsErrorCode[res.data.code] ? msg : res.data.msg);
      }
      if (!url.includes('datalog/events')) {
        reportEvent('User_Error_Reported', {
          Error_type: `o[${method}]${url}: ${JSON.stringify(res)}`
        });
      }
      return Promise.reject(res.data);
    }
  };

  const failHandler = (error: Response<Error>) => {
    afterRequest?.();
    if (error.message === 'canceled') {
      console.log('🚀 ~ request aborted: ', url);
      return;
    }
    throw new Error(error.message || 'Error');
  };

  beforeRequest?.();

  method = method || 'GET';

  const params = Object.assign(typeof data === 'function' ? data() : data ?? {}, {});

  const runtimeConfig = useRuntimeConfig();
  url = runtimeConfig.public.baseUrl + url;

  if (method === 'GET') {
    return request.get(url, { params, signal, responseType, onDownloadProgress }).then(successHandler, failHandler);
  } else if (method === 'PUT') {
    return request.put(url, params, { headers, signal, onDownloadProgress }).then(successHandler, failHandler);
  } else if (method === 'DELETE') {
    return request.delete(url, { params, headers, signal, onDownloadProgress }).then(successHandler, failHandler);
  } else if (method === 'POST') {
    return request.post(url, params, { headers, signal, responseType, onDownloadProgress, onUploadProgress }).then(successHandler, failHandler);
  } else {
    return request.patch(url, params, { headers, signal, onDownloadProgress }).then(successHandler, failHandler);
  }
}

const createRequest = <T = any>(method: string, options: HttpOption) => {
  return http<T>({
    method,
    showSuccess: false,
    showError: true,
    ...options
  });
};

export const get = <T = any>(options: HttpOption) => createRequest<T>('GET', options);
export const post = <T = any>(options: HttpOption) => createRequest<T>('POST', options);
export const put = <T = any>(options: HttpOption) => createRequest<T>('PUT', options);
export const del = <T = any>(options: HttpOption) => createRequest<T>('DELETE', options);
export const patch = <T = any>(options: HttpOption) => createRequest<T>('PATCH', options);
