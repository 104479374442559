/*
 * @Author: Sandy
 * @Date: 2024-01-21 12:36:00
 * @LastEditTime: 2024-01-22 10:41:40
 * @Description: 
 */
const DISTANCE = 150;
const DURATION = 500;
const map = new WeakMap();
const isBelowViewport = (el: Element) => {
  const rect = el.getBoundingClientRect();
  return rect.bottom < 0 || rect.top - window.innerHeight > 0;
  // return rect.top > window.innerHeight + DISTANCE;
}

const ob = new IntersectionObserver((entries) => {
  for (const entry of entries) {
    if(entry.isIntersecting){
      // entry.target.getAnimations()
      map.get(entry.target).play();
      ob.unobserve(entry.target);
    }
  }
});

export default {
  mounted(el: any) {
    if(!isBelowViewport(el)) return;
    const animation = el.animate([
      {
        transform: `translateY(${DISTANCE}px)`,
        opacity: 0.5,
      }, {
        transform: "translateY(0)",
        opacity: 1,
      }
    ], {
      duration: DURATION,
      easing: "ease-out",
      fill: "forwards",
    });

    animation.pause();
    ob.observe(el);
    map.set(el, animation);
  },
  unmounted(el: any) {
    ob.unobserve(el);
  }
}