import { defineStore } from 'pinia';
import { getFeatCost, getUserAsset } from '~/apis/chat';

export const useFeatCostStore = defineStore('featCost', {
  state: (): FeatCostStore => ({
    featCost: {},
    userAssets: {
      plan: 'free',
      credits_limit: 0,
      next_credits_limit: 0,
      credits: 0,
      arvin_gpt4: {
        count: 0,
        amount: 0,
        enable: true
      },
      PDF_SUMMARIZE: {
        count: 0,
        amount: 0,
        enable: true
      },
      WEB_ACCESS: {
        count: 0,
        amount: 0,
        enable: true
      },
      WEB_SUMMARIZE: {
        count: 0,
        amount: 0,
        enable: true
      },
      YOUTUBE_SUMMARIZE: {
        count: 0,
        amount: 0,
        enable: true
      },
      pro_gpts_switch: false,
      invited_credits: 0,
      asset_item: {},
      can_try: true,
      org_user: false,
      annually: false,
      plan_usage: {}
    }
  }),
  getters: {
    count (): number {
      return this.userAssets.credits;
    },
    userAsset (): UserAsset {
      return this.userAssets;
    }
  },
  actions: {
    setUserAssets (asset: UserAsset) {
      this.userAssets = asset;
    },
    setFeatCost (featCost: FeatCost) {
      this.featCost = featCost;
    },
    async getAssets () {
      const data = await getUserAsset();
      this.userAssets = data;
    },
    async getFeatCost () {
      const data = await getFeatCost();
      this.featCost = data;
    }
  },
  persist: {
    key: '__feat_cost_assets',
    storage: persistedState.localStorage
  }
});
