import { defineStore } from 'pinia';
import { getAllFeat } from '~/apis/chat';

export const useFeat = defineStore('feat', {
  state: () => ({
    prompts: {} as any
  }),
  actions: {
    setAllFeat () {
      getAllFeat('en').then((res: any) => {
        this.prompts = res;
      });
    }
  }
});
