import CryptoJS from 'crypto-js';
import packageJson from '../package.json';
import { getUserInfo, getBrowserName, getDeviceId, judgeMobile } from '~/composables/useUtils';
import { arvinReportCheckAP, getApSwitch } from '~/apis/user';
import prodConfig from '~/config/env.production';
import devConfig from '~/config/env.development';
import { useFeatCostStore } from '~/store/featCost';
import { useLocation } from '~/composables/useLocation';

class ARAES {
  key = '';

  constructor (key: string) {
    this.key = key;
  }

  encrypt (data: unknown) {
    let raw = JSON.stringify(data);
    const ciphertext = CryptoJS.AES.encrypt(
      raw,
      CryptoJS.enc.Utf8.parse(this.key),
      {
        mode: CryptoJS.mode.ECB
      }
    );
    return ciphertext.toString();
  }

  decrypt (data: unknown) {
    const bytes = CryptoJS.AES.decrypt(
      // @ts-ignore
      data,
      CryptoJS.enc.Utf8.parse(this.key),
      {
        mode: CryptoJS.mode.ECB
      }
    );
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }
}

function uuid () {
  let s = [];
  let hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  // @ts-ignore
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  let uuid = s.join('');
  return uuid;
}

let cipher = new ARAES('eh0kdg3amo2uc8eb');

// function getFirstOpenTime() {
//   const localTime = window.localStorage.getItem('__first_open_time__');
//   let time = new Date();
//   if (localTime) {
//     time = new Date(localTime);
//   } else {
//     window.localStorage.setItem('__first_open_time__', time?.toString());
//   }
//   return new Date(time);
// }

function ajax (originOption: { url: string, method?: string, data: string }) {
  let { url, method = 'get', data } = originOption;
  let options = {
    method,
    headers: {},
    body: ''
  };
  // @ts-ignore
  options.headers['Content-Type'] = 'application/json;charset=UTF-8';
  options.body = data;

  return fetch(url, options)
    .then(async (response) => {
      try {
        let method = 'text';
        // @ts-ignore
        let json = await response[method]();
        if (response.ok) {
          return json;
        } else {
          return Promise.reject(json.error || json);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(new Error(err.message || err));
    });
}
interface ReportEventParams {
  event_name: string;
  params: any;
}

async function getSentryBaseParams () {
  const { getCountry } = useLocation();

  const country = await getCountry();
  let deviceId = getDeviceId() || 'NO';

  const runtimeConfig = useRuntimeConfig();
  const env = runtimeConfig.public.env as any;
  const fileMap: any = {
    development: devConfig,
    test: devConfig,
    production: prodConfig
  };

  const { getStoredUtmParams, defaultUtmParams } = useUtmTracking();
  const utmParams = getStoredUtmParams(defaultUtmParams);
  const { plan, asset_item, annually } = useFeatCostStore().userAssets;
  const apSwitchData = await getApSwitch();

  let { userId, email, register_time, first_login_time } = getUserInfo();
  const subscription_type = plan && (plan !== 'free') ? `${asset_item?.is_trial_period ? 'free_' : ''}${annually ? 'annual' : 'month'}` : '';

	const route = useRoute();
	const config = useNuxtApp().$arvinConfig as any;
  const versionName =
    packageJson.version + (fileMap[env].env === '_develop' ? '_dev' : '');
  return {
    country,
    user_segment: 99,
    media_source: 'arvin web',
    browser_name: getBrowserName(),
    plugin_version_name: versionName,
    user_pseudo_id: deviceId,
    custom_user_id: deviceId,
    product_feature: 'official',
    landing_page: localStorage?.getItem('landing_page') || '',
    referrer: document.referrer,
    router_referrer: route.fullPath ? config.homepageUrl + route.fullPath : '',
    ap_test_case_grammar: '',
    ap_test_case_web_pricing: apSwitchData.pricing_popup_ui_test_case || '',
    ap_test_case_benefit: '',
    webapp_user_first_open_timestamp: '0',
    extension_user_first_open_timestamp: '0',
    platform_type: judgeMobile() ? 'mobile' : 'pc',
    user_mail_address: email || 'undefined',
    app_user_first_open_timestamp: new Date(first_login_time || null),
    user_id: userId || 'undefined',
    app_event_timestamp: new Date(),
    user_register_time: register_time ? new Date(register_time) : null,
    user_identity: plan || 'undefined',
    subscription_type,
    ...utmParams
  };
}

export async function araesBaseParams () {
  try {
    if (!cipher) {
      cipher = new ARAES('eh0kdg3amo2uc8eb');
    }
    const params = await getSentryBaseParams();
    const result = cipher.encrypt(params);
    return result;
  } catch (error) {
    console.error(error);
    return '';
  }
}

export async function reportEventToMetaBase (event_name: string, params = {}) {
  let events = [
    {
      event_id: uuid(),
      event_name
    }
  ];

  const baseParams = await getSentryBaseParams();
  let param = {
    ...baseParams,
    events,
    ...params
  };
  console.table(param);
  let realData = cipher.encrypt(param);
  const casApiUrl = 'https://caspian-data.tryarvin.com/caspian/datalog/events';
  try {
    navigator.sendBeacon(casApiUrl, realData);
  } catch (err) {
    ajax({
      method: 'post',
      url: casApiUrl,
      data: realData
    })
      .then(() => { })
      .catch((result) => {
        if (result.err) {
          let err = cipher.decrypt(result.err);
          try {
            let realErr = JSON.parse(err);
            console.error(realErr.error);
          } catch (e) {
            // console.error(err);
          }
        }
      });
  }
}

export async function reportEvent (event_name: string, params: any = {}, type = 'metaBase') {
  if (type === 'metaBase') {
    await reportEventToMetaBase(event_name, params);
  } else {
    await arvinReportCheckAP(event_name, params);
  }
}

// Multiple events are reported
export async function reportEventToAll (options: {
  ap: ReportEventParams | ReportEventParams[],
  metaBase?: ReportEventParams
}) {
  if (Array.isArray(options.ap)) {
    options.ap.map(m => reportEvent(m.event_name, m.params, 'ap'));
  } else {
    options.ap.event_name && reportEvent(options.ap.event_name, options.ap.params, 'ap');
  }
  options.metaBase && reportEvent(options.metaBase.event_name, options.metaBase.params, 'metaBase');
}
