import { getConfig } from '@/config';
import slideInDirective from '@/directives/slideIn';
import dragDirective from '@/directives/drag';
import lazyLoadVideo from '~/directives/lazyLoadVideo';

export default defineNuxtPlugin((nuxtApp) => {
  const { initUTM } = useUtmTracking();

  initUTM();

  nuxtApp.vueApp.directive('drag', dragDirective);
  nuxtApp.vueApp.directive('slide-in', slideInDirective);
  nuxtApp.vueApp.directive('lazy-load-video', lazyLoadVideo);
  const runtimeConfig = useRuntimeConfig();

  const env = runtimeConfig.public.env as any;

  nuxtApp.provide('arvinConfig', getConfig(env));
});
