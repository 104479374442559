const country = ref('NO');
const isEU = ref(false);
const isLoaded = ref(false);
export const useLocation = () => {
  const getLocation = async () => {
    const location = await fetch('https://ipapi.co/json/')
    .then(response => response.json()) as {country:string, in_eu:boolean};

    country.value = location.country;
    isEU.value = location.in_eu;
    isLoaded.value = true;
    return location;
  };

  const getCountry = async () => {
    if (isLoaded.value) {
      return country.value;
    }
    await getLocation();
    return country.value;
  };

  const getEU = async () => {
    if (isLoaded.value) {
      return isEU.value;
    }
    await getLocation();
    return isEU.value;
  };

  return {
    country,
    isEU,
    getLocation,
    getCountry,
    getEU
  };
};
