export const appBaseURL = "/"

export const appCdnURL = ""

export const appBuildAssetsDir = "/_nuxt/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"description","content":"Take your writing to the next level with Arvin, your AI writing assistant. Try Arvin's advanced AI content generator tools now!"},{"property":"og:locale","content":"en_US"},{"property":"og:type","content":"website"},{"property":"og:title","content":"Arvin - Your AI Writing Assistant & Best AI Content Generator"},{"property":"og:site_name","content":"Arvin"},{"property":"og:image","content":"https://arvin.chat/wp-content/uploads/2023/04/Arvin-1.png"},{"property":"og:image:width","content":"1024"},{"property":"og:image:height","content":"1024"},{"name":"google-site-verification","content":"XRGzk8Fn1zmqNqYe8oY1xgW9MHL1envYJiWliH2Uec8"},{"property":"og:image:type","content":"image/png"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"}],"link":[{"rel":"dns-prefetch","href":"https://public-cdn.tryarvin.com"},{"rel":"preload","as":"style","href":"https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700&display=swap","onload":"this.onload=null;this.rel='stylesheet'"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"},"title":"ChatGPT Prompt Generator | GPT-4o Prompts"}

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false