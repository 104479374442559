import prodConfig from './env.production';
import devConfig from './env.development';
import type { Config } from './types';

const fileMap: any = {
  development: devConfig,
  test: devConfig,
  production: prodConfig
};

export function getConfig (env: 'development' | 'test' | 'production'): Config {
  console.log('env:', env);

  return {
    strategy: 'arvin_asset',
    initUserPrompts: [
      {
        name: 'Summarize',
        content: 'You are a writing assistant. Please summarize the text.',
        pinned: true
      },
      {
        name: 'Improve writing',
        content: 'Please improve the flow and readability of the text.'
      },
      {
        name: 'Explain',
        content: 'Explain this text and break down any technical terms used.'
      },
      {
        name: 'Explain Codes',
        content: 'Explain the following codes.'
      },
      {
        name: 'Translate',
        content: 'Translate the following content to English.'
      },
      {
        name: 'Rewrite',
        content: 'Rewrite the following content.'
      }
    ],
    defaultSettings: {
      youTubeSummarizer: true,
      webSummarizer: true,
      gmail: true,
      twitter: true,
      linkedIn: true,
      quickActions: true,
      selectContext: true,
      searchBox: true,
      searchBoxConfig: {
        when: 'Manually',
        result: 'Ask Arvin'
      },
      score: 0,
      composeUserDefined: {}
    },
    ...fileMap[env]
  };
}
