export const textDict: {
  [key: string] : {
    desc: string,
    info: {
      title: string,
      subTitle: string,
      desc: string
    }
  }
} = {
  word: {
    desc: 'Convert your PDF to Word documents with incredible accuracy.',
    info: {
      title: 'PDF to Word Converter',
      subTitle: 'Efficiently convert PDF files into editable Word files',
      desc: 'No need to manually enter the content on PDF file, just upload the PDF to quickly convert pdf to word document with the format and layout preserved, efficiently change the text content, etc.'
    }
  },
  png: {
    desc: 'Easily convert PDF to PNG images in seconds.',
    info: {
      title: 'PDF to PNG Converter',
      subTitle: 'Efficiently convert PDF file to PNG',
      desc: 'No need to take a PDF as a photo, you can quickly obtain a clearer PNG image by uploading and converting the PDF file to PNG with just one click.'
    }
  },
  jpg: {
    desc: 'Best way to convert PDF to JPG online, for free.',
    info: {
      title: 'PDF to JPG Converter',
      subTitle: 'Efficiently convert PDF to JPG Images',
      desc: 'No downloads, no ad watermarks - just a great pdf to jpg online tool to convert your PDF pages to images.'
    }
  }
};

export const howToUse = [
  {
    title: 'Upload Your PDF',
    subTitle: 'Drag & drop or import your PDF file to our converter.',
    picture: '/imgs/pdf-tools/upload.png'
  },
  {
    title: 'PDF to {type} converter',
    subTitle: 'Convert files quickly and retain their original format.',
    picture: '/imgs/pdf-tools/{type}-converter.png'
  },
  {
    title: 'Download',
    subTitle: 'Save your converted {type} file locally.',
    picture: '/imgs/pdf-tools/{type}-download.png'
  }
];

export const benefits = [
  {
    icon: '🔒',
    title: 'Top-notch Security',
    desc: 'Arvin protects data from leakage with 256-bit SSL encryption.'
  },
  {
    icon: '⚡️',
    title: 'Millisecond conversion speed',
    desc: `Once you've uploaded the file, you'll be able to get the converted {type} file in a matter of seconds.`
  },
  {
    icon: '✅',
    title: 'Precise and efficient conversions',
    desc: 'Support uploading PDF files within 20M, and the format and layout of the original file will be retained after conversion to ensure the readability and validity of the information.'
  }
];

export const moteTools = [
  {
    title: 'PDF to Word',
    desc: 'Make PDF files easy to edit by converting them to Word Files.',
    url: '/pdf-tools/pdf-to-word',
    icon: 'word'
  },
  {
    title: 'PDF to JPG',
    desc: 'Convert each PDF page into a JPG or extract all images contained in a PDF.',
    url: '/pdf-tools/pdf-to-jpg',
    icon: 'jpg'
  },
  {
    title: 'PDF to PNG',
    desc: 'Convert each PDF page into a PNG or extract all images contained in a PDF.	',
    url: '/pdf-tools/pdf-to-png',
    icon: 'png'
  },
  {
    title: 'Background Remover',
    desc: 'Remove image backgrounds automatically in just 5 seconds with a single click.',
    url: '/image-tools/background-remover',
    icon: 'bg-remover'
  },
  {
    title: 'Background Changer',
    desc: 'Effortlessly change image backgrounds with 1-click.',
    url: '/image-tools/background-changer',
    icon: 'bg-changer'
  },
  {
    title: 'Sketch to image',
    desc: 'Bring your sketches to life! Convert simple drawings into stunning, realistic images with just a few clicks.',
    url: '/image-tools/sketch-to-image',
    icon: 'pen'
  },
  {
    title: 'Image to Image',
    desc: 'Easily create variations of your images from image to image, similar but different.',
    url: '/image-tools/image-to-image',
    icon: 'img-to-img'
  },
  {
    title: 'Image replacer',
    desc: 'Easily remove objects from images and replace them with our ai photo editor.',
    url: '/image-tools/ai-photo-editor',
    icon: 'image-replacer'
  },
  {
    title: 'Remove Text',
    desc: 'Easily remove unwanted text from any image while maintaining its original quality and aesthetics.',
    url: '/image-tools/remove-text-from-image',
    icon: 'remove-text'
  }
];
