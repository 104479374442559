/*
 * @Author: Sandy
 * @Date: 2023-09-22 14:15:03
 * @LastEditTime: 2024-06-12 11:06:38
 * @Description:
 */
import { get, http, post } from '@/hooks/request';
import { getConfigByStorageLazyFetch, promiseWithState } from '~/composables/useUtils';

export const getUserProfile = () => {
  return get<User>({
    url: '/api/users/profile'
  });
};

export const getInviterInfo = (invite_code : string) => {
  return get<User>({
    url: '/api/users/inviter/profile',
    data: {
      invite_code
    }
  });
};

export const getInviteRewords = () => {
  return get({
    url: '/api/users/invite/rewards'
  });
};

export const getProductConfig = () => {
  return get({
    url: '/api/asset/product_configs'
  });
};

export function getUserAsset () {
  return get<UserAsset>({
    url: `/api/users/v2/asset`
  });
}

// Report to the server
export function arvinReportCheck (params: {event: 'view_prompt' | 'use_prompt' | string, args?: Record<string, any> }) {
  // base64 encode
  const s = btoa(JSON.stringify(params));
  return post(
    {
      url: `/api/check`,
      data: { s }
    }
  );
}

// Report Events to the AP server
export function arvinReportCheckAP (topic_id: string, events: Record<string, number>) {
  const token = getArvinSessionToken();
  if (!token) { return; }
  return post(
    {
      url: `/api/check/ap_submit`,
      data: {
        topic_id,
        events
      }
    }
  );
}

export function arvinReportGAProfile (data: {client_id: string, session_id: string, is_google_login?: boolean}) {
  return post(
    {
      url: `/api/ga4/profile/upsert`,
      data
    }
  );
}

export function checkEmailStatus (email: string) {
  return post(
    {
      url: `/api/users/email_status`,
      data: {
        email
      },
      showError: false
    }
  );
}

export function sendEmailVerifyCode (email: string) {
  return post(
    {
      url: `/api/users/signup_code`,
      data: {
        email
      }
    }
  );
}

export function sendFindPasswordCode (email: string) {
  return post(
    {
      url: `/api/users/forgot_email_password`,
      data: {
        email
      }
    }
  );
}

export function registerWithEmail (params: {email: string, password: string, verify_code: string}) {
  return post(
    {
      url: `/api/users/signup`,
      data: params
    }
  );
}

export function resetPassword (params: {email: string, code: string, password: string}) {
  return post(
    {
      url: `/api/users/reset_email_password`,
      data: params
    }
  );
}

export function registerDeviceId () {
  return post(
    {
      url: `/api/users/ap_register`
    }
  );
}

let getApSwitchFun:any;
export async function getApSwitch (key?: string, time = 5 / 60) {
  !getApSwitchFun && (getApSwitchFun = promiseWithState(http));
  let data = await getConfigByStorageLazyFetch<{
    [key: string]: boolean | number | string
  }>({
    key: 'ap_switch',
    time,
    callFunc: () => getApSwitchFun({
      url: `/api/ap/switch`,
      showSuccess: false,
      showError: true
    })
  });
  return key ? data[key] : data;
}
