export const ImageToolsErrorCode: Record<number, string> = {
  40601: "Email code verification failed. Please try again later.",
  40603: "Email already registered. Please use a different email.",
  40605: "User does not exist. Please check your email address or register for a new account.",
  40607: "Invalid email or password. Please check your email and password combination.",
  40608: "Verification code cannot be empty. Please enter a valid verification code.",
  40612: "Invalid verification code. Please enter the correct code.",
  40613: "Service is currently undergoing an upgrade. Please try again later.",
  40614: "Service  is currently busy. Please try again later.",
  40615: "Email code not verified. Please enter the correct email code to proceed.",
  40616: "Failed to send email code. Please try again later.",
  40617: "Password reset failed. Please try again later.",
  40618: "Failed to send registration email verification code. Please try again later."
}