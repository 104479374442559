export const textDict : {
  [key: string]: {
    subTitle: string;
    desc: string;
  }
} = {
  spanish: {
    subTitle: 'Accurately check and correct Spanish language grammar.',
    desc: 'The Spanish grammar checker provides grammar checking and correction for Spanish text, improving the accuracy of written communication.'
  },
  french: {
    subTitle: 'Accurately check and correct French language grammar.',
    desc: `The French grammar checker offers grammar checking and correction for French text, ensuring grammatical correctness and clarity. `
  },
  german: {
    subTitle: 'Thoroughly analyze and correct German language grammar.',
    desc: 'The German grammar checker provides grammar checking and correction for German text, improving writing accuracy and coherence. '
  },
  chinese: {
    subTitle: 'Accurately identify and rectify Chinese language grammar errors.',
    desc: 'The Chinese grammar checker provides grammar checking and correction for Chinese text, enhancing clarity and coherence in writing. '
  },
  japanese: {
    subTitle: 'Thoroughly analyze and correct Japanese grammar for accurate writing.',
    desc: 'The Japanese grammar checker offers grammar checking and correction for Japanese text, enhancing writing accuracy and clarity. '
  },
  korean: {
    subTitle: 'Precisely identify and rectify Korean language grammar errors.',
    desc: 'The Korean Grammar Checker provides grammar checking services for Korean text, helping writers convey their messages clearly.'
  },
  italian: {
    subTitle: 'Detect and rectify grammar issues in Italian language writing.',
    desc: `The Italian Grammar Checker offers grammar checking and correction for Italian text, improving the accuracy of written content.`
  },
  russian: {
    subTitle: 'Accurately analyze and correct Russian language grammar mistakes.',
    desc: 'The Russian grammar checker provides grammar checking services for Russian text, enhancing clarity and correctness in writing. '
  }
};

export const howToUse = [
  {
    title: 'Type Your Content',
    subTitle: 'Paste or input the {lang} text.',
    picture: '/imgs/grammar/step1.png'
  },
  {
    title: 'Grammar Check',
    subTitle: 'Click on "Check Grammar" to analyze the text.',
    picture: '/imgs/grammar/step2.png'
  },
  {
    title: 'Get Optimized Results',
    subTitle: 'See the correct text after AI correction.',
    picture: '/imgs/grammar/{lang}-step.png'
  }
];
export const howToUseForInsCaption = [
  {
    title: 'Type Your Content',
    subTitle: 'Please describe the content and purpose of the post, including any relevant hashtags or emojis.',
    picture: '/imgs/creator/ins-caption-step1.png'
  },
  {
    title: 'Generate',
    subTitle: 'Click generate to obtain an Instagram caption.',
    picture: '/imgs/creator/step2.png'
  },
  {
    title: 'Get Optimized Results',
    subTitle: `Modify the caption to fit your brand's voice and the post's message.`,
    picture: '/imgs/creator/step3.png'
  }
];
export const howToUseForInsBio = [
  {
    title: 'Type Your Content',
    subTitle: `Enter your key information (name, interests, etc.) and choose your tone (quirky, professional, etc.).`,
    picture: '/imgs/creator/ins-bio-step1.png'
  },
  {
    title: 'Generate',
    subTitle: 'Click generate to get a bio.',
    picture: '/imgs/creator/step2.png'
  },
  {
    title: 'Get Optimized Results',
    subTitle: 'Modify and personalize the bio to match your style.',
    picture: '/imgs/creator/step3.png'
  }
];
export const allGrammarTools = [
  {
    key: 'spanish',
    emoji: '🇪🇸',
    title: 'Spanish Grammar Checker',
    desc: 'Accurately check and correct Spanish language grammar.'
  },
  {
    key: 'french',
    emoji: '🇫🇷',
    title: 'French Grammar Checker',
    desc: 'Accurately check and correct French language grammar.'
  },
  {
    key: 'german',
    emoji: '🇩🇪',
    title: 'German Grammar Checker',
    desc: 'Thoroughly analyze and correct German language grammar.'
  },
  {
    key: 'chinese',
    emoji: '🇨🇳',
    title: 'Chinese Grammar Checker',
    desc: 'Accurately identify and rectify Chinese language grammar errors.'
  },
  {
    key: 'japanese',
    emoji: '🇯🇵',
    title: 'Japanese Grammar Checker',
    desc: 'Thoroughly analyze and correct Japanese grammar for accurate writing.'
  },
  {
    key: 'korean',
    emoji: '🇰🇷',
    title: 'Korean Grammar Checker',
    desc: 'Precisely identify and rectify Korean language grammar errors.'
  },
  {
    key: 'italian',
    emoji: '🇮🇹',
    title: 'Italian Grammar Checker',
    desc: 'Detect and rectify grammar issues in Italian language writing.'
  },
  {
    key: 'russian',
    emoji: '🇷🇺',
    title: 'Russian Grammar Checker',
    desc: 'Accurately analyze and correct Russian language grammar mistakes.'
  }
];

export const FAQList = [
  {
    q: 'What is a {lang} grammar checker?',
    a: 'A {lang} grammar checker is a tool that uses artificial intelligence to identify and correct grammar, spelling, and punctuation errors in written {lang} content.'
  },
  {
    q: 'How does it work?',
    a: 'The Al grammar checker analyzes the text, compares it to grammar rules, and suggests corrections for mistakes, improving the accuracy and clarity of the writing.'
  },
  {
    q: 'Can a {lang} grammar checker help improve language skills?',
    a: 'Yes, consistent use of a grammar checker can help users learn from their mistakes and gradually improve their {lang} writing proficiency.'
  },
  {
    q: 'Can a grammar checker detect nuanced language errors?',
    a: 'While Al grammar checkers are effective at identifying common errors, they might not catch subtle context-related errors that require human interpretation.'
  },
  {
    q: 'Is a {lang} grammar checker suitable for professional writing?',
    a: 'Yes, a grammar checker can enhance the professionalism of your written content by ensuring grammatical correctness and clarity.'
  }
];

export const benefits = [
  {
    icon: '✨',
    title: 'Advanced Grammar and Spelling Checks',
    desc: 'Detects and corrects comprehensive grammar and spelling errors, addressing complex verb conjugations and gender agreement with precision.'
  },
  {
    icon: '🛠️',
    title: 'Fix Punctuation',
    desc: 'Ensures accurate punctuation, crucial for maintaining the natural flow and readability of {lang} texts.'
  },
  {
    icon: '📚',
    title: 'Full-sentence Rewrites',
    desc: 'Provides suggestions for rewriting sentences to enhance clarity and coherence, making your {lang} writing more accessible.'
  },
  {
    icon: '💡',
    title: 'Customize Paraphrasing',
    desc: 'Offers paraphrasing options to enrich vocabulary and avoid repetition, ensuring your writing is engaging and lively.'
  },
  {
    icon: '📝',
    title: 'Style and Tone',
    desc: 'Adjustments Tailors your writing to the appropriate style and tone, whether formal or informal, ensuring your {lang} texts resonate with your intended audience. '
  }
];

export const benefitsForBirthday = [
  {
    icon: '✨',
    title: 'Personalized Customization',
    desc: 'Customize various styles of birthday wishes for different objects to make the wishes more thoughtful and meaningful.'
  },
  {
    icon: '♾️',
    title: 'Endless Creativity',
    desc: 'AI can combine the latest pop culture, trend elements or unique literary styles to generate creative and innovative blessings to help you stand out.'
  },
  {
    icon: '⏰',
    title: 'Save Time',
    desc: 'AI can complete this task in seconds, saving time significantly.'
  }
];

export const benefitsForInsCaption = [
  {
    icon: '✨',
    title: 'Personalized Content',
    desc: 'AI can generate personalized caption suggestions based on the styles and preferences of different users.'
  },
  {
    icon: '✅',
    title: 'language Accuracy',
    desc: 'AI can combine the latest pop culture, trend elements or unique literary styles to generate creative and innovative blessings to help you stand out.'
  },
  {
    icon: '⏰',
    title: 'Save Time',
    desc: 'Creating attractive captions by hand can take a lot of time, especially for brands or individuals who frequently need to publish content. AI can quickly generate captions, saving valuable time.'
  }
];

export const benefitsForInsBio = [
  {
    icon: '✨',
    title: 'Personalized Customization',
    desc: 'AI can combine personal information, interests and activities to create a personalized Bio, making the Bio appear more personal or brand-specific.'
  },
  {
    icon: '♾️',
    title: 'Endless Creativity',
    desc: 'Sometimes people get stuck in a creative bottleneck. AI can provide a series of creative Bio options to provide users with inspiration.'
  },
  {
    icon: '🔍',
    title: 'SEO Optimization',
    desc: 'AI can help synthesize keywords to improve the search engine ranking of Instagram accounts.'
  }
];

export const grammarBLogs = [
  {
    title: 'Understanding Tone Tags: Meaning and Usage Tips',
    desc: 'Birthdays aren’t just dates on the calendar; they’re your chance to shower your extraordinary wife with love and joy!',
    image: '/imgs/blog/1.png',
    url: 'https://arvin.chat/blog/tone-tags/'
  },
  {
    title: '15 Writing Strategies Every Writer Should Know',
    desc: 'Got a sis with the tightest bond, but you’re drawing a blank on birthday wishes?',
    image: '/imgs/blog/2.png',
    url: 'https://arvin.chat/blog/writing-strategies/'
  },
  {
    title: '25 Best Funny Email Sign-Offs You Should Try',
    desc: 'Birthdays are a special occasion. Hence, it’s the prime moment to show your appreciation to your boss. ',
    image: '/imgs/blog/3.png',
    url: 'https://arvin.chat/blog/funny-email-sign-offs/'
  }
];
