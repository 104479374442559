import { post } from '@/hooks/request';
// import type { LoginItf } from '~/types';

export function loginArvin (data: LoginItf) {
  return post({
    url: `/api/users/login`,
    data
  });
}

export function signOut () {
  return post({
    url: `/api/users/logout`
  });
}
