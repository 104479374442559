export const pathMap: any = {
  'act-as-a-sales-development-representative-sdr-to-follow-up-with-a-potential-lead': 'sales-development-representative-(sdr)-to-follow-up-with-a-potential-lead',
  'act-as-a-feature-to-benefit-small-business-owners': 'feature-to-benefit-small-business-owners',
  'boost-the-online-presence': 'boost-the--online-presence',
  'act-as-a-sales-development-representative-sdr-to-find-more-clues-among-potential-clients': 'sales-development-representative-(sdr)-to-find-more-clues-among-potential-clients',
  'act-as-a-networking-and-partnerships-guide': 'networking-and-partnerships-guide',
  'act-as-a-primary-product-manager': 'act-as-a-product-manager',
  'act-as-a-sales-development-representative-sdr-for-a-potential-contract-for-the-product': 'sales-development-representative-(sdr)-for-a-potential-contract-for-the-product',
  'act-as-a-white-paper-provider': 'white-paper-provider',
  'act-as-a-guide-for-using-the-before-after-bridge-bab-framework': 'guide-for-using-the-before-after-bridge-(bab)-framework',
  'act-as-a-companys-vision-statement': "company's-vision-statement",
  'act-as-a-google-ad-description-writer': 'google-ad-description-writer',
  'act-as-an-event-plan-generator': 'event-plan-generator',
  'act-as-a-presentation-provider-for-a-startup-company': 'presentation-provider-for-a-startup-company',
  'act-as-a-business-storyline-creator': 'business-storyline-creator',
  'act-as-an-all-hands-agenda-coordinator': 'all-hands-agenda-coordinator',
  'act-as-a-sales-development-representative-sdr-to-follow-up-with-an-unresponsive-lead-2': 'sales-development-representative-(sdr)-to-follow-up-with-an-unresponsive-lead',
  'act-as-a-sales-development-representative-sdr-to-learn-more-information': 'sales-development-representative-(sdr)-to-learn-more-information',
  'act-as-a-sales-development-representative-sdr-to-call-with-current-clients': 'sales-development-representative-(sdr)-to-call-with-current-clients',
  'act-as-a-business-plan-generator': 'business-plan-generator',
  'act-as-a-sales-development-representative-sdr-to-reach-out-to-a-potential-client': 'sales-development-representative-(sdr)-to-reach-out-to-a-potential-client',
  'act-as-a-sales-development-representative-sdr-to-meet-with-the-executives': 'sales-development-representative-(sdr)-to-meet-with-the-executives',
  'act-as-an-industry-analyst': 'industry-analyst',
  'act-as-a-sales-development-representative-sdr-to-follow-up-with-a-potential-client': 'sales-development-representative-(sdr)-to-follow-up-with-a-potential-client',
  'act-as-a-sales-development-representative-sdr-to-send-an-email-to-potential-client': 'sales-development-representative-(sdr)-to-send-an-email-to-potential-client',
  'streamlining-operations-for-startup-name': 'streamlining-operations-for-[startup-name]',
  'product-name-feature-prioritization': '[product-name]-feature-prioritization',
  'help-companies-uncover-actionable-insights': 'help-companies-uncover-actionable-insights',
  'develop-a-plan-for-improving-diversity-equity-and-inclusion-in-the-workplace': 'develop-a-plan-for-improving-diversity,-equity,-and-inclusion-in-the-workplace',
  'drafting-a-non-disclosure-agreement-nda': 'drafting-a-non-disclosure-agreement-(nda)',
  'generate-investor-list-for-startup-name': 'generate-investor-list-for-[startup-name]',
  'evaluate-pros-and-cons-of-dropshipping-vs-holding-inventory': 'evaluate-pros-and-cons-of-dropshipping-vs.-holding-inventory',
  'evaluate-the-potential-of-early-stage-startups-in-the-industry-sector': 'evaluate-the-potential-of-early-stage-startups-in-the-[industry]-sector',
  'create-a-catchy-slogan-for-new-line-of-category-products': 'create-a-catchy-slogan-for-new-line-of-[category]-products',
  'benefits-of-using-project-management-software-to-manage-the-teams-tasks': `benefits-of-using-project-management-software-to-manage-the-team's-tasks`,
  'improve-teams-communication-and-collaboration': "improve-team's-communication-and-collaboration",
  'share-the-meetings-agenda': 'share-the-meeting’s-agenda',
  'use-web-analytics-to-track-the-websites-performance': "use-web-analytics-to-track-the-website's-performance"
};

export const pathMapSEO: {[key:string]: {
  keywords: string;
  title: string;
  description: string;
}} = {
  'chatgpt-prompts-for-designers/create-a-poster-design-for-an-upcoming-event/': {
    keywords: 'chatgpt poster making, chatgpt poster generator',
    title: 'Best ChatGPT Poster Making Prompts for Designers',
    description: `Are you trying to maximize the benefits of ChatGPT poster generator to make stunning posters? Try Arvin's ChatGPT poster making prompts.`
  },
  'chatgpt-prompts-for-midjourney/best-midjourney-prompts-for-ui-design/': {
    keywords: 'midjourney ui ux prompts, midjourney ui design prompts',
    title: '100+ Best Midjourney UI UX Prompts for Designers ',
    description: 'Explore the best Midjounrney UI UX prompts that can help you create unique web interfaces and experiences for your website’s visitors. '
  },
  'chatgpt-prompts-for-data-analysis/': {
    keywords: 'chatgpt data analysis prompts',
    title: 'ChatGPT Data Analysis Prompts: Ultimate Workflow Booster ',
    description: 'Using our selected data analysis prompts can greatly improve your efficiency when dealing with numerable data. Try now! '
  },
  'chatgpt-prompts-for-health/act-as-a-psychologist/': {
    keywords: 'chatgpt therapist prompt, chatgpt psychologist prompt',
    title: 'ChatGPT Therapist Prompt: Your Privat Mental Guide ',
    description: 'Discover how ChatGPT therapist prompt can upgrade your mental health journey, providing customized support with ChatGPT psychologist prompt '
  },
  'chatgpt-prompts-for-designers/design-a-logo-for-a-new-brand/': {
    keywords: 'logo design prompt generator, chatgpt logo prompt',
    title: 'Logo Design Prompt Generator: Inspire Branding Creativity ',
    description: 'Fuel creativity with our ChatGPT logo prompt. Inspire unique concepts for your brand with our easy-to-use logo design prompt generator. '
  },
  'chatgpt-prompts-for-finance/': {
    keywords: 'finance prompts for chatgpt',
    title: '100+ Finance Prompts for ChatGPT Collection ',
    description: 'Empower your ChatGPT conversations with our selected finance prompts for ChatGPT. From investing to budgeting, explore a wealth of topics. '
  },
  'chatgpt-prompts-for-health/act-as-an-ai-assisted-doctor/': {
    keywords: 'chatgpt doctor prompt, chatgpt medical diagnosis prompt',
    title: 'ChatGPT Doctor Prompt: Medical Advice Toolbox ',
    description: 'Optimize medical dialogues with ChatGPT doctor prompt. Integrate ChatGPT medical diagnosis prompt for accurate discussions. '
  },
  'chatgpt-prompts-for-writing/act-as-a-regex-generator/': {
    keywords: 'chatgpt regex prompt',
    title: 'Empower Coding: ChatGPT Regex Prompt Toolkit ',
    description: `Supercharge your coding skills with Arvin's ChatGPT regex prompt. Access a toolkit for precise pattern matching and validation. `
  },
  'chatgpt-prompts-for-teaching/act-as-a-math-teacher/': {
    keywords: 'chatgpt math prompt',
    title: 'Math Made Fun: Explore ChatGPT Math Prompt ',
    description: 'Elevate your math skills using ChatGPT prompts. Dynamic and engaging prompts for mastering mathematical concepts. '
  },
  'chatgpt-prompts-for-speech/': {
    keywords: 'chatgpt prompts for speech writing',
    title: 'Illuminate Audiences: ChatGPT Prompts for Speech Writing ',
    description: "Ignite inspiration with Arvin's ChatGPT prompts for speech writing. Create speeches that resonate and captivate your audience. "
  },
  'chatgpt-prompts-for-writing/act-as-a-screenwriter/': {
    keywords: 'chatgpt screenwriting prompts',
    title: 'Best ChatGPT Screenwriting Prompts to Ignite Script Talent ',
    description: "Elevate your screenwriting with Arvin's ChatGPT screenwriting prompts. Explore dynamic scenarios and plot twists for compelling scripts. "
  },
  'chatgpt-prompts-for-writing/act-as-a-title-generator-for-written-pieces/': {
    keywords: 'chatgpt title generator',
    title: 'Boost Engagement with ChatGPT Title Generator ',
    description: 'Discover the power of ChatGPT title generator to create attention-grabbing titles that drive traffic and engagement. Try it now! '
  },
  'general-chatgpt-prompts/act-as-an-astrologer/': {
    keywords: 'chatgpt astrology prompts',
    title: 'Discover Your Destiny with ChatGPT Astrology Prompts ',
    description: 'Experience the power of ChatGPT astrology prompts to explore your horoscope and discover what the future holds for you. Try it now! '
  },
  'chatgpt-prompts-for-writing/act-as-a-journalist/': {
    keywords: 'chatgpt prompts for journalists',
    title: 'Fuel Your Reporting: ChatGPT Prompts for Journalists ',
    description: "Get an edge over your competition with Arvin's ChatGPT prompts for journalists. Boost your writing skills and creativity today. "
  },
  'chatgpt-prompts-for-writing/write-a-motivational-quote/': {
    keywords: 'chatgpt quote generator',
    title: 'Create Memorable Quotes with ChatGPT Quote Generator ',
    description: 'Stand out from the crowd with ChatGPT quote generator. Create memorable and shareable quotes that will engage your audience. '
  },
  'chatgpt-prompts-for-sales/write-a-brochure-about-the-product/': {
    keywords: 'chatgpt brochure maker',
    title: 'Craft Stunning Product Brochures with ChatGPT Brochure Maker ',
    description: 'Take the hassle out of brochure design with ChatGPT brochure maker. Create eye-catching brochures in minutes and impress your customers. '
  },
  'chatgpt-prompts-for-marketing/act-as-a-content-calendar-generator/': {
    keywords: 'chatgpt prompts for content calendar',
    title: '20+ Best ChatGPT Prompts for Content Calendar ',
    description: 'Take your social media marketing to the next level with ChatGPT prompts for content calendar. Plan, create, and engage like never before. '
  },
  'general-chatgpt-prompts/act-as-a-journal-reviewer/': {
    keywords: 'chatgpt reviewer',
    title: 'Get Expert Feedback with ChatGPT Reviewer ',
    description: "Get expert feedback on your article with Arvin's ChatGPT reviewer. Identify strengths and weaknesses and improve your work. "
  },
  'chatgpt-prompts-for-designers/act-as-an-interior-decorator/': {
    keywords: 'chatgpt interior design prompts',
    title: 'Best ChatGPT Interior Design Prompts to Create Stylish Decor ',
    description: 'Revolutionize your decor with ChatGPT interior design prompts. Elevate aesthetics and bring unparalleled comfort to your spaces. '
  },
  'chatgpt-prompts-for-business/act-as-a-chief-executive-officer/': {
    keywords: 'chatgpt ceo prompt',
    title: 'CEO Insights: Explore The Power of ChatGPT CEO Prompt ',
    description: 'Gain valuable insights with ChatGPT CEO prompt. Navigate strategic decisions, optimize financial performance, and represent with impact. '
  },
  'chatgpt-prompts-for-designers/': {
    keywords: 'graphic design prompts generator',
    title: 'Best Graphic Design Prompts Generator for Designers',
    description: 'Get a creative boost with the best graphic design prompts generator. Unlock your artistic potential and craft standout designs effortlessly. '
  },
  'chatgpt-prompts-for-midjourney/midjourney-prompt-for-character-design/': {
    keywords: 'midjourney prompts for characters',
    title: 'Midjourney Prompts for Characters Made Design Simple',
    description: 'Bring characters to life in seconds! Use Midjourney Prompts for chracters to create visually captivating character designs effortlessly. '
  }
}

export const oldPathIdList = Object.keys(pathMap);
